import React, {useEffect, useRef, useState} from 'react';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    Box,
    MenuItem,
    Select,
    FormControl,
    TextField,
    TablePagination,
    InputAdornment,
    Typography,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import {Search as SearchIcon} from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

import {useSelector} from 'react-redux';
import {StoreState} from '../types/store-state';
import {useParams} from 'react-router-dom';
import {linkPath} from '../helpers/history';
import {isFunction, isString, isEmpty} from 'lodash';
import {PAGINATION_OPTIONS} from '../constants/pagination';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    formControl: {
        margin: 10,
        minWidth: 150,
    },
    title: {
        display: 'inline-block',
    },
    iconDehazeStyle: {
        marginLeft: '30px',
        marginBottom: '-6px',
        fontSize: '2.0rem',
        cursor: 'pointer',
    },
    iconAppStyle: {
        marginLeft: '10px',
        marginBottom: '-4px',
        fontSize: '1.8rem',
        cursor: 'pointer',
    },
    activeButtonIcon: {
        color: '#3B86FF',
    },
    rowName: {
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#4D4F5C',
        opacity: 1,
    },
    block: {
        fontWeight: 'bold',
        color: 'red',
    },
    active: {
        fontWeight: 'bold',
        color: '#00FF62',
    },
    filterRow: {
        background: 'none',
        cursor: 'auto',
        height: '32px',
    },
    textFieldFilter: {},
    tableContainer: {
        position: 'relative',
        boxShadow: '0px 0px 30px 0px rgba(82, 63, 105, 0.05)',
        fontSize: 13,
        borderRadius: 5,
        padding: '20px 20px 0',
        backgroundColor: '#FFF',
        '& .MuiTableCell-root': {
            borderBottom: '1px solid #EBEDF3',
        },
        '& .MuiTableFooter-root': {
            '& .MuiTableCell-root': {
                borderBottom: 'none !important',
            },
        },
        '& ::-webkit-scrollbar': {
            width: 8,
        },
        '& ::-webkit-scrollbar-track': {
            borderRadius: 10,
        },
        '& ::-webkit-scrollbar-thumb': {
            background: '#f5f5f7',
            borderRadius: 10,
            border: '1px solid #f5f5f7',
        },
    },
    headerStyle: {
        color: '#B5B5C3',
        fontWeight: 600,
        backgroundColor: '#fff',
        position: 'sticky',
        top: 0,
        zIndex: 10,
    },
    headerActionStyle: {
        color: '#B5B5C3',
        fontWeight: 600,
        backgroundColor: '#fff',
        position: 'sticky',
        top: 0,
        zIndex: 10,
        width: '5%',
    },
    rowStyle: {
        color: '#3F4254',
    },
    searchFieldStyle: {
        fontSize: 13,
    },
    overlayTable: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
        backgroundColor: 'rgb(255 255 255 / 0.6)',
    },
    toolbarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '15px 0 15px 15px',
    },
});

interface IOptions {
    value: any;
    text: string;
}
interface IData {
    id: string;
    [key: string]: any;
}
export interface ITableFilter {
    type: 'string' | 'array';
    default?: any;
    options?: IOptions[];
}

export interface ITableHeader {
    title: string;
    field: string;
    render?: (rowData: any) => void;
    filter?: ITableFilter;
}

interface IAction {
    icon: any;
    tooltip: any;
    onClick: (event: any, rowData: any) => void;
    isHidden?: (rowData: any) => boolean;
}

interface IProps {
    path?: string;
    headers: ITableHeader[];
    isFilter?: boolean;
    filter?: any;
    loadAction: (request: any) => void;
    onClickToRow?: (id: string) => void;
    data: IData[];
    page: number;
    limit: number;
    count: number;
    clearTable?: () => void;
    title?: any;
    isLoading: boolean;
    searchText: string;
    actions?: IAction[];
    tableHeight?: number;
}

const CustomTable = (props: IProps) => {
    const {notification} = useSelector((state: StoreState) => state);
    const {
        headers,
        isFilter,
        filter,
        data,
        path,
        limit,
        onClickToRow,
        loadAction,
        page,
        count,
        title,
        isLoading,
        searchText,
        actions,
        tableHeight,
    } = props;
    const timeoutRef = useRef<any>(null);
    const routerParams = useParams<any>();
    const [searchValue, setSearchValue] = useState<string>(searchText);
    const classes = useStyles();

    const filterDebounce = (event: any, type: any, filter: any) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        //
        timeoutRef.current = setTimeout(() => {
            loadAction({
                filter: {
                    ...filter,
                    [type]: event.target.value,
                },
                pageNumber: 1,
                pageSize: limit,
                searchText: searchText,
            });
        }, 500);
    };

    useEffect(() => {
        loadAction({
            pageNumber: routerParams.page ? parseInt(routerParams.page, 0) : 1,
            pageSize: limit,
            searchText: routerParams.search ? routerParams.search : searchText,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSearchValue(searchText);
    }, [searchText, setSearchValue]);

    useEffect(() => {
        /// handle call api success
        if (
            notification &&
            notification.isShow &&
            notification.type === 'success'
        ) {
            loadAction({
                pageNumber: routerParams.page
                    ? parseInt(routerParams.page, 0)
                    : 1,
                pageSize: limit,
                searchText: routerParams.search
                    ? routerParams.search
                    : searchText,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    const buildPath = (
        selectedPage: number = page,
        currentSearchText: string = searchText,
    ) => {
        let newPath = '';
        if (path) {
            newPath = linkPath(path, 'page?', selectedPage.toString());
            newPath = linkPath(newPath, 'search?', currentSearchText);
        }
        return newPath;
    };

    const handleChangePage = (_event: any, page: number) => {
        loadAction({
            pageNumber: page + 1,
            pageSize: limit,
            searchText: searchText,
        });
        if (path) {
            window.history.pushState(null, '', buildPath(page + 1, searchText));
        }
    };

    const handleChangeFilter = (type: string) => (event: any) => {
        event.persist();
        filterDebounce(event, type, filter);
    };

    const handleChangeLimit = (event: any) => {
        loadAction({
            pageNumber: 1,
            pageSize: parseInt(event.target.value, 0),
            searchText: searchText,
        });
        if (path) {
            window.history.pushState(null, '', buildPath(1));
        }
    };

    const handleOnClickToRow = (rowData: any) => (_event: any) => {
        if (onClickToRow) {
            onClickToRow(rowData);
        }
    };

    const onTableSearchChange = (event: any) => {
        event.persist();
        const value = event.target.value;
        setSearchValue(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        //
        timeoutRef.current = setTimeout(() => {
            loadAction({
                pageNumber: 1,
                pageSize: limit,
                searchText: value,
            });
            if (path) {
                window.history.pushState(null, '', buildPath(1, value));
            }
        }, 500);
    };

    return (
        <Box className={classes.tableContainer}>
            {isLoading ? (
                <Box className={classes.overlayTable}>
                    <CircularProgress />
                </Box>
            ) : null}
            <Box className={classes.toolbarContainer}>
                {title ? (
                    <Box>
                        {isString(title) ? (
                            <Typography variant="h5">{title}</Typography>
                        ) : (
                            title
                        )}
                    </Box>
                ) : null}
                <TextField
                    label="Search"
                    type="search"
                    variant="outlined"
                    value={searchValue ?? ''}
                    onChange={onTableSearchChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <TableContainer style={{height: tableHeight ?? 700}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((value) => (
                                <TableCell
                                    key={value.field}
                                    className={classes.headerStyle}
                                >
                                    {value.title}
                                </TableCell>
                            ))}
                            {actions && !isEmpty(actions) ? (
                                <TableCell
                                    key={'table-action'}
                                    className={classes.headerStyle}
                                >
                                    Action
                                </TableCell>
                            ) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isFilter && (
                            <TableRow className={classes.filterRow}>
                                {headers.map((value, index) => {
                                    if (!value.filter) return null;
                                    switch (value.filter.type) {
                                        case 'string':
                                            return (
                                                <TableCell
                                                    key={index}
                                                    style={{paddingLeft: 0}}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth={true}
                                                        onChange={handleChangeFilter(
                                                            value.field,
                                                        )}
                                                        className={
                                                            classes.textFieldFilter
                                                        }
                                                    />
                                                </TableCell>
                                            );
                                        case 'array':
                                            return (
                                                <TableCell
                                                    align="center"
                                                    key={index}
                                                    style={{
                                                        width: '100px',
                                                        paddingLeft: 0,
                                                    }}
                                                >
                                                    <FormControl
                                                        variant="outlined"
                                                        className={
                                                            classes.formControl
                                                        }
                                                    >
                                                        <Select
                                                            autoWidth={true}
                                                            displayEmpty={true}
                                                            value={
                                                                filter &&
                                                                filter[
                                                                    value.field
                                                                ]
                                                                    ? filter[
                                                                          value
                                                                              .field
                                                                      ]
                                                                    : value
                                                                          .filter
                                                                          .default
                                                            }
                                                            onChange={handleChangeFilter(
                                                                value.field,
                                                            )}
                                                        >
                                                            {value.filter.options?.map(
                                                                (el, idx) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                idx
                                                                            }
                                                                            value={
                                                                                el.value
                                                                            }
                                                                        >
                                                                            {
                                                                                el.text
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                },
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            );
                                        default:
                                            break;
                                    }
                                    return null;
                                })}
                            </TableRow>
                        )}

                        {data.map((row, key) => (
                            <TableRow
                                key={key}
                                onClick={handleOnClickToRow(row)}
                            >
                                {headers.map((value, fieldKey) => (
                                    <TableCell
                                        key={`${fieldKey}-${key}`}
                                        className={classes.rowStyle}
                                    >
                                        {isFunction(value.render)
                                            ? value.render(row)
                                            : row[value.field]}
                                    </TableCell>
                                ))}
                                {actions && !isEmpty(actions) ? (
                                    <TableCell
                                        key={key}
                                        className={classes.rowStyle}
                                    >
                                        {actions.map((action, actionKey) =>
                                            isFunction(action.isHidden) &&
                                            action.isHidden(row) ? null : (
                                                <Tooltip
                                                    title={
                                                        isFunction(
                                                            action.tooltip,
                                                        )
                                                            ? action.tooltip(
                                                                  row,
                                                              )
                                                            : action.tooltip
                                                    }
                                                    key={actionKey}
                                                >
                                                    <IconButton
                                                        onClick={(e) =>
                                                            action.onClick(
                                                                e,
                                                                row,
                                                            )
                                                        }
                                                    >
                                                        {isFunction(action.icon)
                                                            ? action.icon(row)
                                                            : action.icon}
                                                    </IconButton>
                                                </Tooltip>
                                            ),
                                        )}
                                    </TableCell>
                                ) : null}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Table>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            count={count}
                            page={page - 1}
                            rowsPerPage={limit}
                            rowsPerPageOptions={PAGINATION_OPTIONS}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeLimit}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </Box>
    );
};

export default CustomTable;
