import React, {useState} from 'react';
import {Grid, Typography, Button, makeStyles, Link} from '@material-ui/core';
import {isValidEmail} from '../../helpers/validate';
import {Input} from '../../components/Input';
import {find} from 'lodash';
import {Checkbox} from '../../components/Checkbox';
import {signUpMiddleware} from '../../middlewares/auth';
import {ReCaptcha} from '../../components/ReCAPTCHA';

const styles = makeStyles({
    autofill: {
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            borderRadius: 3,
            padding: 12,
        },
    },
});

export const SignUp = () => {
    const classes = styles();
    const [state, setState] = useState({
        email: {
            value: '',
            error: '',
        },
        password: {
            value: '',
            error: '',
        },
        repassword: {
            value: '',
            error: '',
        },
        reCaptcha: {
            value: '',
            error: '',
        },
    });

    const [checkBoxState, setCheckboxState] = useState({
        readPrivacy: false,
        subscribe: false,
    });

    const handleSubmit = () => {
        signUpMiddleware({
            email: state.email.value.trim().toLocaleLowerCase(),
            password: state.password.value,
            googleReCaptchaResponse: state.reCaptcha.value,
            subscribe: checkBoxState.subscribe,
        });
    };

    const onChangeText = (key: 'email' | 'password' | 'repassword') => (
        event,
    ) => {
        switch (key) {
            case 'email':
                setState({
                    ...state,
                    [key]: {
                        error:
                            isValidEmail(event.target.value) === false
                                ? 'Email is invalid'
                                : '',
                        value: event.target.value,
                    },
                });
                break;
            case 'password':
                setState({
                    ...state,
                    password: {
                        error:
                            event.target.value.length < 6
                                ? 'Password is too short'
                                : '',
                        value: event.target.value,
                    },
                    repassword: {
                        error: !state.repassword.value
                            ? ''
                            : event.target.value.length < 6
                            ? 'Confirm password is too short'
                            : event.target.value !== state.repassword.value
                            ? 'Password is not match'
                            : '',
                        value: state.repassword.value,
                    },
                });
                break;
            case 'repassword':
                setState({
                    ...state,
                    password: {
                        error:
                            state.password.value.length < 6
                                ? 'Password is too short'
                                : '',
                        value: state.password.value,
                    },
                    repassword: {
                        error: !state.repassword.value
                            ? ''
                            : event.target.value.length < 6
                            ? 'Confirm password is too short'
                            : event.target.value !== state.password.value
                            ? 'Password not match'
                            : '',
                        value: event.target.value,
                    },
                });
        }
    };

    const toggleCheckbox = (key: 'readPrivacy' | 'subscribe') => () => {
        setCheckboxState({
            ...checkBoxState,
            [key]: !checkBoxState[key],
        });
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && disableSubmit === false) {
            event.preventDefault();
            handleSubmit();
        }
    };

    const onChangeReCaptcha = (token: string | null) =>
        setState({
            ...state,
            reCaptcha: {
                value: token || '',
                error: '',
            },
        });

    const disableSubmit =
        Boolean(find(state, (el) => el.value === '' || el.error !== '')) ||
        checkBoxState.readPrivacy === false;

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
                height: `calc(100vh)`,
                position: 'relative',
                backgroundColor: 'lightGrey',
            }}
            className={classes.autofill}
        >
            <img
                src={''}
                alt="Logo"
                style={{position: 'absolute', top: 40, left: 40}}
            />
            <Typography variant="h1" style={{color: 'white'}}>
                Sign up
            </Typography>
            <Typography variant="subtitle1">
                Please enter your personal information
            </Typography>

            <Grid
                container
                direction="column"
                justify="space-between"
                style={{
                    backgroundColor: 'white',
                    height: 600,
                    width: 444,
                    marginTop: 28,
                    marginBottom: 20,
                    padding: '40px 30px',
                    boxShadow: '0px 0px 100px #162CA7',
                    borderRadius: 3,
                }}
            >
                <Input
                    label="Email"
                    type="email"
                    value={state.email.value}
                    helperText={state.email.error}
                    error={state.email.error ? true : false}
                    onChange={onChangeText('email')}
                    onKeyPress={onKeyPress}
                />
                <Input
                    label="Password"
                    type="password"
                    value={state.password.value}
                    helperText={state.password.error}
                    error={state.password.error ? true : false}
                    onChange={onChangeText('password')}
                    onKeyPress={onKeyPress}
                    autoComplete={'new-password'}
                    style={{marginTop: 20}}
                />
                <Input
                    label="Confirm Password"
                    type="password"
                    value={state.repassword.value}
                    helperText={state.repassword.error}
                    error={state.repassword.error ? true : false}
                    onChange={onChangeText('repassword')}
                    onKeyPress={onKeyPress}
                    style={{marginTop: 20}}
                />

                <Checkbox
                    label={
                        <Grid container>
                            <Typography
                                variant="body1"
                                style={{marginRight: 4}}
                            >
                                I’ve read and understood the{' '}
                            </Typography>
                            <Link href="#" target="_blank">
                                Privacy Policy
                            </Link>
                        </Grid>
                    }
                    checked={checkBoxState.readPrivacy}
                    onChange={toggleCheckbox('readPrivacy')}
                />
                <Checkbox
                    label="I would like to receive occasional e-mails with discounts and offers"
                    checked={checkBoxState.subscribe}
                    onChange={toggleCheckbox('subscribe')}
                />

                <ReCaptcha
                    onChange={onChangeReCaptcha}
                    containerStyle={{marginTop: 20}}
                />

                <Grid container justify="center" style={{marginTop: 30}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={disableSubmit}
                    >
                        Sign Up
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
