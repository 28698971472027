import {Action} from 'redux';
import {IWithdraw} from '../types/withdraw';

////////////////////////////////////////////////////////
export const GET_WITHDRAWS_REQUEST = 'GET_WITHDRAWS_REQUEST';
export type GET_WITHDRAWS_REQUEST = typeof GET_WITHDRAWS_REQUEST;

export interface IGetWithdrawsRequestAction
    extends Action<GET_WITHDRAWS_REQUEST> {}
export const getWithdrawsRequest = (): IGetWithdrawsRequestAction => {
    return {
        type: GET_WITHDRAWS_REQUEST,
    };
};

////////////////////////////////////////////////////////
export const SET_WITHDRAWS_RESPONSE = 'SET_WITHDRAWS_RESPONSE';
export type SET_WITHDRAWS_RESPONSE = typeof SET_WITHDRAWS_RESPONSE;

export interface ISetWithdrawsResponseAction
    extends Action<SET_WITHDRAWS_RESPONSE> {
    payload: IWithdraw;
}

export const setWithdraws = (
    payload: IWithdraw,
): ISetWithdrawsResponseAction => {
    return {
        type: SET_WITHDRAWS_RESPONSE,
        payload,
    };
};
