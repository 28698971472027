import {store} from '..';
import {setRanks, getRanksRequest} from '../actions/rank';
import Axios, {AxiosResponse, AxiosError} from 'axios';

import {
    fireErrorNotification,
    fireSuccessNotification,
} from '../helpers/notification';
import {IRank, IRankRequest} from '../types/rank';
import {IPaginationRequest} from '../types/pagination';

export const getRanks = (params: IPaginationRequest) => {
    const {pageNumber, pageSize} = params;
    store.dispatch(getRanksRequest());
    Axios.get(`/Rank/GetRanks?PageNumber=${pageNumber}&PageSize=${pageSize}`)
        .then((response: AxiosResponse<IRank>) => {
            store.dispatch(setRanks(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const addRank = (params: IRankRequest) => {
    Axios.post('/Rank', params)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const editRank = (params: IRankRequest, id: string) => {
    Axios.put(`/Rank/${id}`, params)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
export const deactiveRank = (id: string) => {
    Axios.put(`/Rank/Deactive/${id}`)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
export const activeRank = (id: string) => {
    Axios.put(`/Rank/Active/${id}`)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
