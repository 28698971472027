import React, {useEffect, useRef} from 'react';
import {Grid} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {StoreState} from '../types/store-state';
import ReCAPTCHA from 'react-google-recaptcha';
import {REACT_APP_RECAPTCHA_SITE_KEY} from '../helpers/environment';
interface Props {
    onChange?: (token: string | null) => void;
    containerStyle?: React.CSSProperties;
}
interface IGrecaptcha {
    props: {
        grecaptcha: {
            reset: () => void;
        };
    };
}

export const ReCaptcha = (props: Props) => {
    const {onChange, containerStyle} = props;
    const captcha = useRef<ReCAPTCHA & IGrecaptcha>(null);
    const {notification} = useSelector((state: StoreState) => state);

    useEffect(() => {
        if (notification) {
            if (notification.isShow && notification.type === 'error') {
                captcha.current?.props?.grecaptcha?.reset();
            }
        }
    }, [notification]);

    return (
        <Grid
            container
            justify="center"
            style={{height: 80, ...containerStyle}}
        >
            <ReCAPTCHA
                hl={'en'}
                ref={captcha}
                size={'normal'}
                sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={onChange}
                badge="bottomright"
            />
        </Grid>
    );
};
