import {IUser, IUserDataShow, IUserPlan, IUserBalance} from '../types/user';
import {IWithdraw} from '../types/withdraw';
import {
    GET_USERS_REQUEST,
    IGetUsersRequestAction,
    SET_USERS_RESPONSE,
    ISetUsersResponseAction,
    SET_USER_SHOW,
    ISetUserShowResponseAction,
    IGetUserPlanRequestAction,
    GET_USER_PLAN_REQUEST,
    SET_USER_PLAN_RESPONSE,
    ISetUserPlanResponseAction,
    GET_USER_WITHDRAW_REQUEST,
    IGetUserWithdrawRequestAction,
    SET_USER_WITHDRAW_RESPONSE,
    ISetUserWithdrawResponseAction,
    SET_USER_BALANCE_RESPONSE,
    ISetUserBalanceResponseAction,
} from '../actions/user';
import {DEFAULT_PAGE_SIZE} from '../constants/pagination';

const initState: IUser = {
    data: [],
    pageInfo: {
        totalCount: 0,
        currentPage: 1,
        totalPages: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        searchText: '',
    },
    isLoading: false,
};

export const user = (
    state: IUser = initState,
    action: ISetUsersResponseAction | IGetUsersRequestAction,
) => {
    if (action.type === GET_USERS_REQUEST) {
        return {
            ...state,
            isLoading: true,
        };
    }

    if (action.type === SET_USERS_RESPONSE) {
        return {
            ...state,
            data: action.payload.data,
            pageInfo: action.payload.pageInfo,
            isLoading: false,
        };
    }
    return state;
};
///////////////////////////////////////////
export const userShow = (
    state: IUserDataShow | null = null,
    action: ISetUserShowResponseAction,
) => {
    if (action.type === SET_USER_SHOW) {
        return action.payload;
    }
    return state;
};

///////////////////////////////////////////
const initPlanState: IUserPlan = {
    data: [],
    pageInfo: {
        totalCount: 0,
        currentPage: 1,
        totalPages: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        searchText: '',
    },
    isLoading: false,
};

export const userPlan = (
    state: IUserPlan = initPlanState,
    action: IGetUserPlanRequestAction | ISetUserPlanResponseAction,
) => {
    if (action.type === GET_USER_PLAN_REQUEST) {
        return {
            ...state,
            isLoading: true,
        };
    }

    if (action.type === SET_USER_PLAN_RESPONSE) {
        return {
            ...state,
            data: action.payload.data,
            pageInfo: action.payload.pageInfo,
            isLoading: false,
        };
    }
    return state;
};
///////////////////////////////////////////
const initWithdrawState: IWithdraw = {
    data: [],
    pageInfo: {
        totalCount: 0,
        currentPage: 1,
        totalPages: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        searchText: '',
    },
    isLoading: false,
};

export const userWithdraw = (
    state: IWithdraw = initWithdrawState,
    action: IGetUserWithdrawRequestAction | ISetUserWithdrawResponseAction,
) => {
    if (action.type === GET_USER_WITHDRAW_REQUEST) {
        return {
            ...state,
            isLoading: true,
        };
    }

    if (action.type === SET_USER_WITHDRAW_RESPONSE) {
        return {
            ...state,
            data: action.payload.data,
            pageInfo: action.payload.pageInfo,
            isLoading: false,
        };
    }
    return state;
};

export const userBalances = (
    state: IUserBalance[] = [],
    action: ISetUserBalanceResponseAction,
) => {
    if (action.type === SET_USER_BALANCE_RESPONSE) {
        return action.payload;
    }
    return state;
};
