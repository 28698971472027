import {Action} from 'redux';
import {IPlan} from '../types/plan';

////////////////////////////////////////////////////////
export const GET_PLANS_REQUEST = 'GET_PLANS_REQUEST';
export type GET_PLANS_REQUEST = typeof GET_PLANS_REQUEST;

export interface IGetPlansRequestAction extends Action<GET_PLANS_REQUEST> {}
export const getPlansRequest = (): IGetPlansRequestAction => {
    return {
        type: GET_PLANS_REQUEST,
    };
};

////////////////////////////////////////////////////////
export const SET_PLANS_RESPONSE = 'SET_PLANS_RESPONSE';
export type SET_PLANS_RESPONSE = typeof SET_PLANS_RESPONSE;

export interface ISetPlansResponseAction extends Action<SET_PLANS_RESPONSE> {
    payload: IPlan;
}

export const setPlans = (payload: IPlan): ISetPlansResponseAction => {
    return {
        type: SET_PLANS_RESPONSE,
        payload,
    };
};
