import {LoginRequest, VerifyTwoFactorRequest} from '../types/auth';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {pushTo} from '../helpers/history';
import {PATH} from '../constants/path';
import {fireErrorNotification} from '../helpers/notification';
import {getUserProfileMiddleware} from './setting';

export const loginMiddleware = (request: LoginRequest) => {
    Axios.post('/Auth/AdminLogin', request)
        .then((response: AxiosResponse<{token: string} & {data: string}>) => {
            const secretCode = response.data.data;
            if (secretCode) {
                sessionStorage.setItem('secretCode', secretCode);
                pushTo(PATH.login2fa);
                return;
            }
            localStorage.setItem('token', response.data.token);
            pushTo(PATH.plans);
            getUserProfileMiddleware();
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
export const verifyTwoFactor = (request: VerifyTwoFactorRequest) => {
    Axios.post('/Auth/Login2FA', request)
        .then(
            (
                response: AxiosResponse<{
                    token: string;
                }>,
            ) => {
                sessionStorage.removeItem('secretCode');
                localStorage.setItem('token', response.data.token);
                pushTo(PATH.plans);
                getUserProfileMiddleware();
            },
        )
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
            if (error.response?.status === 404) {
                /// secretCode expired
                sessionStorage.removeItem('secretCode');
                pushTo(PATH.login);
            }
        });
};

export const signUpMiddleware = (
    request: LoginRequest & {subscribe?: boolean},
) => {
    Axios.post('/auth/register', request)
        .then(() => {
            pushTo(PATH.signUpSuccess);
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const forgotPasswordMiddleware = (request: {
    email: string;
    googleReCaptchaResponse: string;
}) => {
    Axios.post('/auth/forgotpassword', request)
        .then(() => {
            pushTo(PATH.forgotPasswordSuccess);
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const resetPasswordMiddleware = (request: {
    password: string;
    token: string;
    id: string;
    callback: () => void;
}) => {
    return Axios.post(`/auth/resetPassword`, request)
        .then(() => {
            request.callback();
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const verifyEmailMiddleware = (request: {
    token: string;
    id: string;
    callback: () => void;
}) => {
    return Axios.post(`/auth/confirmEmail`, request)
        .then(() => {
            request.callback();
        })
        .catch(() => {
            fireErrorNotification(
                'Verify email failed. Redirect to login page after 2s',
            );
            setTimeout(() => pushTo(PATH.login), 3000);
        });
};

export const resendEmailSingUp = (request: {
    token: string;
    id: string;
    callback?: () => void;
}) => {
    return Axios.post(`/auth/resend`, request)
        .then(() => {})
        .catch(() => {
            fireErrorNotification(
                'Verify email failed. Redirect to login page after 2s',
            );
            setTimeout(() => pushTo(PATH.login), 3000);
        });
};
