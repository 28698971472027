import {Action} from 'redux';
import {ICoin, IOverviewCoin, ICoinList} from '../types/coin';

export const SET_COIN = 'SET_COIN';
export type SET_COIN = typeof SET_COIN;

export interface ISetCoinAction extends Action<SET_COIN> {
    payload: ICoin[];
}

export const setCoins = (payload: ICoin[]): ISetCoinAction => {
    return {
        type: SET_COIN,
        payload,
    };
};

////////////////////////////////////////////////////////
export const GET_COIN_LIST_REQUEST = 'GET_COIN_LIST_REQUEST';
export type GET_COIN_LIST_REQUEST = typeof GET_COIN_LIST_REQUEST;

export interface IGetCoinListRequestAction
    extends Action<GET_COIN_LIST_REQUEST> {}
export const getCoinListRequest = (): IGetCoinListRequestAction => {
    return {
        type: GET_COIN_LIST_REQUEST,
    };
};

////////////////////////////////////////////////////////
export const SET_COIN_LIST_RESPONSE = 'SET_COIN_LIST_RESPONSE';
export type SET_COIN_LIST_RESPONSE = typeof SET_COIN_LIST_RESPONSE;

export interface ISetCoinListResponseAction
    extends Action<SET_COIN_LIST_RESPONSE> {
    payload: ICoinList;
}

export const setCoinList = (payload: ICoinList): ISetCoinListResponseAction => {
    return {
        type: SET_COIN_LIST_RESPONSE,
        payload,
    };
};

/////////////////////////////////////////////
export const SET_OVERVIEW_COIN = 'SET_OVERVIEW_COIN';
export type SET_OVERVIEW_COIN = typeof SET_OVERVIEW_COIN;

export interface ISetOverviewCoinAction extends Action<SET_OVERVIEW_COIN> {
    payload: IOverviewCoin[];
}

export const setOverviewCoins = (
    payload: IOverviewCoin[],
): ISetOverviewCoinAction => {
    return {
        type: SET_OVERVIEW_COIN,
        payload,
    };
};
