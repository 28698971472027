import React from 'react';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Typography,
} from '@material-ui/core';
import {isFunction, isEmpty, isString} from 'lodash';

const useStyles = makeStyles({
    tableWrapper: {
        width: '100%',
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableContainer: {
        position: 'relative',
        boxShadow: '0px 0px 30px 0px rgba(82, 63, 105, 0.05)',
        fontSize: 13,
        borderRadius: 5,
        padding: '20px 20px 0',
        backgroundColor: '#FFF',
        '& .MuiTableCell-root': {
            borderBottom: '1px solid #EBEDF3',
        },
        '& .MuiTableFooter-root': {
            '& .MuiTableCell-root': {
                borderBottom: 'none !important',
            },
        },
        '& ::-webkit-scrollbar': {
            width: 8,
        },
        '& ::-webkit-scrollbar-track': {
            borderRadius: 10,
        },
        '& ::-webkit-scrollbar-thumb': {
            background: '#f5f5f7',
            borderRadius: 10,
            border: '1px solid #f5f5f7',
        },
    },
    tableHeadCell: {
        color: '#B5B5C3',
        fontWeight: 600,
        backgroundColor: '#fff',
        position: 'sticky',
        top: 0,
        zIndex: 10,
    },
    tableValueCell: {
        padding: '25px 0 25px 25px',
        fontSize: 13,
        color: '#1e2b35',
        border: 'none',
    },
    textCenter: {
        textAlign: 'center',
    },
    noDataTitle: {
        fontSize: 16,
        fontWeight: 500,
    },
    paginator: {
        float: 'right',
    },
});

interface IProps {
    columns: IColumn[];
    data: any;
    noDataTitle?: string;
}
interface IColumn {
    name: string;
    title: string;
    renderer?: (record: any, value: any) => any;
}

const BasicTable = (props: IProps) => {
    const {columns, data, noDataTitle} = props;
    const classes = useStyles();

    if (isEmpty(data) && isString(noDataTitle)) {
        return (
            <Box className={classes.tableWrapper}>
                <Box className={classes.textCenter}>
                    <Typography className={classes.noDataTitle}>
                        {noDataTitle}
                    </Typography>
                </Box>
            </Box>
        );
    }
    return (
        <Box className={classes.tableContainer}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    className={classes.tableHeadCell}
                                    key={index}
                                >
                                    {column.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((record: any, index: number) => (
                            <TableRow key={index}>
                                {columns.map((column, columnIndex) => (
                                    <TableCell
                                        className={classes.tableValueCell}
                                        key={columnIndex}
                                    >
                                        {isFunction(column.renderer)
                                            ? column.renderer(
                                                  record,
                                                  record[column.name],
                                              )
                                            : record[column.name]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default BasicTable;
