import React, {useEffect, useState} from 'react';
import {verifyEmailMiddleware} from '../../middlewares/auth';
import {Grid, Typography, Link} from '@material-ui/core';
import {PATH} from '../../constants/path';

export const VerifyEmail = ({location}) => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token') || '';
    const id = urlParams.get('id') || '';

    const [isSuccess, setSuccess] = useState(false);

    useEffect(() => {
        verifyEmailMiddleware({
            id,
            token,
            callback: () => setSuccess(true),
        });
        // eslint-disable-next-line
    }, []);

    if (isSuccess) {
        return <VerifyEmailSuccess />;
    }

    return null;
};

const VerifyEmailSuccess = () => {
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
                height: `calc(100vh)`,
                position: 'relative',
                backgroundColor: 'lightGrey',
            }}
        >
            <img
                src={''}
                alt="Logo"
                style={{position: 'absolute', top: 40, left: 40}}
            />

            <Typography variant="h1" style={{color: 'white'}}>
                Verify e-mail successfully
            </Typography>

            <Link href={PATH.login}>Go to login page</Link>
        </Grid>
    );
};
