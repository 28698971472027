import {Action} from 'redux';
import {TwoFactor, IWallet} from '../types/setting';

////////////////////////////////////////////////////////
export const SET_TWO_FACTOR = 'SET_TWO_FACTOR';
export type SET_TWO_FACTOR = typeof SET_TWO_FACTOR;

export interface ISetTwoFactorAction extends Action<SET_TWO_FACTOR> {
    payload: TwoFactor | null;
}

export const setTwoFactorAction = (
    payload: TwoFactor | null,
): ISetTwoFactorAction => {
    return {
        type: SET_TWO_FACTOR,
        payload,
    };
};

////////////////////////////////////////////////////////
export const SET_WALLET = 'SET_WALLET';
export type SET_WALLET = typeof SET_WALLET;

export interface ISetWalletAction extends Action<SET_WALLET> {
    payload: IWallet[];
}

export const setWalletAction = (payload: IWallet[]): ISetWalletAction => {
    return {
        type: SET_WALLET,
        payload,
    };
};
