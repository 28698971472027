import {store} from '..';
import {
    setUsers,
    getUsersRequest,
    setUserShow,
    setUserPlans,
    getUserPlanRequest,
    getUserWithdrawRequest,
    setUserWithdraw,
    setUserBalance,
} from '../actions/user';
import Axios, {AxiosResponse, AxiosError} from 'axios';

import {
    fireErrorNotification,
    // fireSuccessNotification,
} from '../helpers/notification';
import {IUser, IUserDataShow, IUserPlan, IUserBalance} from '../types/user';
import {IWithdraw} from '../types/withdraw';
import {IPaginationRequest} from '../types/pagination';

export const getUsers = (params: IPaginationRequest) => {
    const {pageNumber, pageSize, searchText} = params;
    store.dispatch(getUsersRequest());
    //
    Axios.get(`/User/GetUsers?PageNumber=${pageNumber}&PageSize=${pageSize}`)
        .then((response: AxiosResponse<IUser>) => {
            // inject search text to reducer
            response.data.pageInfo.searchText = searchText;
            ////
            store.dispatch(setUsers(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const getUserShow = (id: string) => {
    Axios.get(`/User/GetUserInformation/${id}`)
        .then((response: AxiosResponse<{data: IUserDataShow}>) => {
            store.dispatch(setUserShow(response.data.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const getUserPlans = (id: string, params: IPaginationRequest) => {
    const {pageNumber, pageSize, searchText} = params;
    store.dispatch(getUserPlanRequest());
    Axios.get(
        `/User/UserPlans/${id}?PageNumber=${pageNumber}&PageSize=${pageSize}`,
    )
        .then((response: AxiosResponse<IUserPlan>) => {
            // inject search text to reducer
            response.data.pageInfo.searchText = searchText;
            store.dispatch(setUserPlans(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
export const getWithdrawHistoryOfUser = (
    id: string,
    params: IPaginationRequest,
) => {
    const {pageNumber, pageSize, searchText} = params;
    store.dispatch(getUserWithdrawRequest());
    Axios.get(
        `/Withdraw/GetWithdrawByUserId/${id}?PageNumber=${pageNumber}&PageSize=${pageSize}`,
    )
        .then((response: AxiosResponse<IWithdraw>) => {
            // inject search text to reducer
            response.data.pageInfo.searchText = searchText;
            store.dispatch(setUserWithdraw(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const getUserBalances = (id: string) => {
    Axios.get(`/User/Balances/${id}`)
        .then((response: AxiosResponse<{data: IUserBalance[]}>) => {
            store.dispatch(setUserBalance(response.data.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
