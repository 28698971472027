import {store} from '..';
import {setUserProfileAction} from '../actions/auth';
import {setTwoFactorAction, setWalletAction} from '../actions/setting';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {
    fireErrorNotification,
    fireSuccessNotification,
} from '../helpers/notification';
import {TwoFactor, IWallet, UserProfile} from '../types/setting';

export const getUserProfileMiddleware = () => {
    Axios.get('/user').then((response: AxiosResponse<{data: UserProfile}>) => {
        store.dispatch(setUserProfileAction(response.data.data));
    });
};

export const updateUserProfileMiddleware = (userProfile: UserProfile) => {
    Axios.put('/user', userProfile)
        .then((response: AxiosResponse) => {
            getUserProfileMiddleware();
            fireSuccessNotification(response.data.message);
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const changePasswordMiddleware = (
    newPassword: string,
    callback: () => void,
) => {
    Axios.post('/auth/changePassword', {newPassword})
        .then((response: AxiosResponse) => {
            fireSuccessNotification(response.data.message);
            callback();
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const get2FactorAuthKey = () => {
    Axios.get('/user/GetTwoFactor')
        .then((response: AxiosResponse<{data: TwoFactor}>) => {
            store.dispatch(setTwoFactorAction(response.data.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const activeTwoFactor = (
    twoFactorCode: string,
    callback: () => void,
) => {
    Axios.post('/user/ActiveTwoFactor', {twoFactorCode})
        .then((response: AxiosResponse) => {
            fireSuccessNotification(response.data.message);
            callback();
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const deactiveTwoFactor = (
    twoFactorCode: string,
    callback: () => void,
) => {
    Axios.post('/user/DeactiveTwoFactor', {twoFactorCode})
        .then((response: AxiosResponse) => {
            fireSuccessNotification(response.data.message);
            callback();
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const getUserWalletsMiddleware = () => {
    Axios.get('/user/wallets').then(
        (response: AxiosResponse<{data: IWallet[]}>) => {
            store.dispatch(setWalletAction(response.data.data));
        },
    );
};

export const updateUserWalletsMiddleware = (wallets: IWallet[]) => {
    const body = wallets.map((wallet) => {
        return {coinCode: wallet.coin.code, address: wallet.address};
    });
    Axios.post('/user/wallets', body)
        .then((response: AxiosResponse) => {
            fireSuccessNotification(response.data.message);
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
