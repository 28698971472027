import React, {useEffect, useState} from 'react';
import {
    Grid,
    Typography,
    Link,
    InputAdornment,
    Menu,
    makeStyles,
    Button,
} from '@material-ui/core';
import {IWallet} from '../../types/setting';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {updateUserWalletsMiddleware} from '../../middlewares/setting';
import {Input} from '../../components/Input';
import {TrendingUp, ArrowDropDown, ArrowDropUp} from '@material-ui/icons';
import {Checkbox} from '../../components/Checkbox';
import {DRAWER_WIDTH} from '../../components/PageLayout';

export const Wallets = () => {
    const {userWallets} = useSelector((state: StoreState) => state);
    const [wallets, setWallets] = useState<IWallet[]>(userWallets);
    useEffect(() => {
        if (userWallets) {
            setWallets(userWallets);
        }
    }, [userWallets]);

    const onChangeAddress = (index: number) => (
        event: React.ChangeEvent<any>,
    ) => {
        wallets[index] = {
            ...wallets[index],
            address: event.target.value,
        };
        setWallets([...wallets]);
    };

    const handleSubmit = () => {
        updateUserWalletsMiddleware(wallets);
    };

    return (
        <Grid container spacing={3}>
            {wallets.map((wallet, index) => (
                <WalletSection
                    key={index}
                    index={index}
                    wallet={wallet}
                    onChangeAddress={onChangeAddress}
                />
            ))}

            <Grid
                container
                justify="center"
                style={{marginTop: 30, marginBottom: 20}}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    menuItem: {
        '&:hover': {
            backgroundColor: 'lightGrey',
        },
    },
});

const WalletSection = (props: {
    wallet: IWallet;
    index: number;
    onChangeAddress: (index: number) => (event: React.ChangeEvent<any>) => void;
}) => {
    const {wallet, index, onChangeAddress} = props;
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState();
    const options = [0.001, 0.01, 0.1, 0.2];
    const [selected, setSelected] = useState(0);

    const openMinimumThresholdMenu = (event: React.ChangeEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };

    const onChangeSelectMinimun = (index: number) => () => {
        setSelected(index);
    };

    const isMenuOpen = Boolean(anchorEl);

    return (
        <>
            <Grid item xs={6}>
                <Typography
                    style={{
                        fontSize: 13,
                        fontWeight: 600,
                        color: '#113a56',
                    }}
                >
                    {wallet.coin.name}
                </Typography>
                <Typography
                    style={{
                        fontSize: 13,
                        color: '#113a56',
                    }}
                >
                    {`You don't have a ${wallet.coin.name} wallet yet? `}
                    <Link
                        href="#"
                        style={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: '#3a4a6b',
                        }}
                    >
                        Click here
                    </Link>
                    {` to get started!`}
                </Typography>
                <Input
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                onClick={openMinimumThresholdMenu}
                                style={{cursor: 'pointer'}}
                            >
                                <TrendingUp />
                                <Typography>
                                    {wallet.coin.minimumWithdraw}
                                </Typography>
                                {isMenuOpen ? (
                                    <ArrowDropUp />
                                ) : (
                                    <ArrowDropDown />
                                )}
                            </InputAdornment>
                        ),
                        style: {
                            marginTop: 20,
                            paddingRight: 4,
                        },
                    }}
                    onChange={onChangeAddress(index)}
                    value={wallet.address}
                />
            </Grid>
            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={() => setAnchorEl(undefined)}
                style={{
                    top: 42,
                    left:
                        index % 2 === 0
                            ? `calc((100vw - ${
                                  DRAWER_WIDTH + 40 + 40 + 24
                              }px) / -2 + 98px)`
                            : -24,
                }}
                PaperProps={{
                    style: {
                        width: `calc((100vw - ${
                            DRAWER_WIDTH + 40 + 40 + 24
                        }px) / 2)`,
                        maxWidth: `calc((100vw - ${
                            DRAWER_WIDTH + 40 + 40 + 24
                        }px) / 2)`,
                        height: 179,
                    },
                }}
                MenuListProps={{
                    style: {
                        paddingTop: 0,
                        width: '100%',
                        justifyContent: 'space-between',
                    },
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    style={{
                        borderBottom: '1px solid #f0f0f1',
                        padding: '0 20px',
                        height: 35,
                    }}
                >
                    <Typography>
                        Select minimum transaction threshold
                    </Typography>
                </Grid>
                {options.map((option, index) => (
                    <Checkbox
                        key={index}
                        label={option}
                        checked={index === selected}
                        onChange={onChangeSelectMinimun(index)}
                        fullWidth
                        labelPlacement="start"
                        value="start"
                        style={{height: 34}}
                        className={classes.menuItem}
                    />
                ))}
            </Menu>
        </>
    );
};
