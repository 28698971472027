import {store} from '..';
import {setPlans, getPlansRequest} from '../actions/plan';
import Axios, {AxiosResponse, AxiosError} from 'axios';

import {
    fireErrorNotification,
    fireSuccessNotification,
} from '../helpers/notification';
import {IPlan, IPlanRequest} from '../types/plan';
import {IPaginationRequest} from '../types/pagination';

export const getPlans = (params: IPaginationRequest) => {
    const {pageNumber, pageSize} = params;
    store.dispatch(getPlansRequest());
    ///api/Plan/GetPlans
    Axios.get(`/Plan/GetPlans?PageNumber=${pageNumber}&PageSize=${pageSize}`)
        .then((response: AxiosResponse<IPlan>) => {
            store.dispatch(setPlans(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const addPlan = (params: IPlanRequest) => {
    Axios.post('/Plan', params)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const editPlan = (params: IPlanRequest, id: string) => {
    Axios.put(`/Plan/${id}`, params)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
export const deactivePlan = (id: string) => {
    Axios.put(`/Plan/DeactivePlan/${id}`)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
export const activePlan = (id: string) => {
    Axios.put(`/Plan/ActivePlan/${id}`)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
export const deletePlan = (id: string) => {
    Axios.delete(`/Plan/${id}`)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
