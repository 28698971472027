import React from 'react';
import {Grid, Typography, makeStyles} from '@material-ui/core';
import {PATH} from '../../constants/path';
import {PageLayout} from '../../components/PageLayout';
import {Route, Switch} from 'react-router-dom';
import {GeneralInformation} from './GeneralInformation';
import {ChangePassword} from './ChangePassword';
import {TwoFactorAuth} from './TwoFactorAuth';
import {Wallets} from './Wallets';
import {pushTo} from '../../helpers/history';

export const MENU_LIST = [
    {
        title: 'General Information',
        path: PATH.generalInformation,
    },
    {
        title: '2Factor Auth',
        path: PATH.twoFactorAuth,
    },
    {
        title: 'Change Password',
        path: PATH.changePassword,
    },
    // {
    //     title: 'Wallets',
    //     path: PATH.wallets,
    // },
];

const useStyles = makeStyles({
    menuItem: {
        borderRadius: 3,
        width: 180,
        height: 30,
        marginRight: 15,
        cursor: 'pointer',
        backgroundColor: '#00607b',
        '&:hover': {
            opacity: '1 !important',
        },
    },
});

export const Settings = ({location}) => {
    const classes = useStyles();

    return (
        <PageLayout>
            <Grid
                container
                style={{
                    backgroundColor: '#fffbf5',
                    padding: 20,
                    borderRadius: 3,
                    height: 'fit-content',
                }}
            >
                <Typography variant="h2" style={{width: '100%'}}>
                    MY ACCOUNT
                </Typography>
                <Typography variant="subtitle1">
                    Update your personal information.
                </Typography>
                <Grid item container style={{marginTop: 30}}>
                    {MENU_LIST.map((item) => (
                        <Grid
                            key={item.path}
                            style={{
                                opacity:
                                    item.path === location.pathname ? 1 : 0.7,
                            }}
                            onClick={() => pushTo(item.path)}
                            container
                            justify="center"
                            alignItems="center"
                            className={classes.menuItem}
                        >
                            <Typography
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    textTransform: 'uppercase',
                                }}
                            >
                                {item.title}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>

                <Grid item container style={{marginTop: 30}}>
                    <Switch>
                        <Route
                            path={PATH.generalInformation}
                            exact
                            component={GeneralInformation}
                        />
                        <Route
                            path={PATH.changePassword}
                            exact
                            component={ChangePassword}
                        />
                        <Route
                            path={PATH.twoFactorAuth}
                            exact
                            component={TwoFactorAuth}
                        />
                        <Route path={PATH.wallets} exact component={Wallets} />
                    </Switch>
                </Grid>
            </Grid>
        </PageLayout>
    );
};
