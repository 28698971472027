import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import {
    makeStyles,
    TextField,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Grid,
    FormHelperText,
    Box,
} from '@material-ui/core';
import {ICoin, ICoinRequest} from '../../types/coin';
import {map, has, isEmpty} from 'lodash';
import {useDropzone} from 'react-dropzone';
import {camelToTitleCase} from '../../helpers/common';
import {POWER_UNITS, DEFAULT_POWER_UNIT} from '../../constants/plan';

///////////////////
const formStyles = makeStyles({
    formGroup: {
        marginBottom: 10,
        width: '100%',
    },
    title: {},
    imageContainer: {
        width: 200,
        height: 200,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: 5,
        cursor: 'pointer',
        border: '2px solid #ffab32',
        borderRadius: 5,
    },
    imageContent: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    iconAdd: {
        backgroundColor: '#3f51b5',
        color: '#FFF',
        marginLeft: 10,
        '&:hover': {
            backgroundColor: '#324092',
        },
    },
    titleAddContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    bagde: {
        fontSize: 13,
        backgroundColor: '#f0b90a',
        marginRight: 15,
        padding: '2px 5px',
        borderRadius: 5,
        color: '#FFF',
    },
});

interface IProps {
    coin: ICoin | null;
    ref: any;
}

const CoinForm = forwardRef((props: IProps, ref: any) => {
    const {coin} = props;
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        multiple: false,
    });

    const [state, setState] = useState({
        name: {value: '', error: ''},
        code: {value: '', error: ''},
        feeWithdraw: {value: 0.001, error: ''},
        minimumWithdraw: {value: 0.001, error: ''},
        decimals: {value: 0, error: ''},
        frozenFlag: {value: 0, error: ''},
        miningFlag: {value: 0, error: ''},
        createWalletUrl: {value: '', error: ''},
        actFlag: {value: 1, error: ''},
        powerUnit: {value: DEFAULT_POWER_UNIT, error: ''},
        algorithm: {value: '', error: ''},
        image: {value: '', error: ''},
    });

    const [base64Image, setBase64Image] = useState('');

    useImperativeHandle(ref, () => ({
        getFormData: getFormData,
        validate: validateData,
    }));

    const getFormData = () => {
        const formData: ICoinRequest = {
            miningFlag: state.miningFlag.value ? true : false,
            name: state.name.value,
            code: state.code.value,
            feeWithdraw: state.feeWithdraw.value,
            minimumWithdraw: state.minimumWithdraw.value,
            decimals: state.decimals.value,
            frozenFlag: state.frozenFlag.value ? true : false,
            actFlag: state.actFlag.value ? true : false,
            createWalletUrl: state.createWalletUrl.value,
            powerUnit: state.powerUnit.value,
            algorithm: state.algorithm.value,
        };
        if (!coin || coin.image !== state.image.value) {
            formData.base64Image = base64Image.split(',')[1];
        }
        return formData;
    };

    useEffect(() => {
        if (coin) {
            const newState = {
                name: {value: coin.name, error: ''},
                code: {value: coin.code, error: ''},
                feeWithdraw: {value: coin.feeWithdraw, error: ''},
                minimumWithdraw: {value: coin.minimumWithdraw, error: ''},
                decimals: {value: coin.decimals, error: ''},
                createWalletUrl: {value: coin.createWalletUrl, error: ''},
                actFlag: {value: coin.actFlag ? 1 : 0, error: ''},
                frozenFlag: {value: coin.frozenFlag ? 1 : 0, error: ''},
                miningFlag: {value: coin.miningFlag ? 1 : 0, error: ''},
                powerUnit: {value: coin.powerUnit, error: ''},
                algorithm: {value: coin.algorithm, error: ''},
                image: {value: coin.image ?? '', error: ''},
            };
            setState(newState);
        }
    }, [coin]);

    useEffect(() => {
        if (!isEmpty(acceptedFiles)) {
            const chosenFile: File = acceptedFiles[0];
            const reader = new FileReader();
            reader.onload = () => {
                const image = reader.result as string;
                setState({
                    ...state,
                    image: {
                        value: image,
                        error: '',
                    },
                });
                setBase64Image(image);
            };
            reader.readAsDataURL(chosenFile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles]);

    const validateData = () => {
        let newState = {...state};
        let isValid = true;
        map(newState, (attribute: any, key: string) => {
            if (has(attribute, 'value')) {
                if (attribute.value === '') {
                    isValid = false;
                    attribute.error = `${camelToTitleCase(
                        key,
                    )} can not be empty`;
                }
                return attribute;
            }
            return attribute.map((child: any, childKey: string) => {
                if (has(child, 'value')) {
                    if (child.value === '') {
                        isValid = false;
                        child.error = `${camelToTitleCase(
                            childKey,
                        )} can not be empty`;
                    }
                }
                return child;
            });
        });
        setState(newState);
        return isValid;
    };

    const onChangeState = (key: string, event: React.ChangeEvent<any>) => {
        event.persist();
        let value = event.target.value;
        // check if value is an number type
        if (!isNaN(parseFloat(value))) {
            if (value < 0) {
                value = 0;
            }
        }
        setState({
            ...state,
            [key]: {
                value: value,
                error: '',
            },
        });
    };

    const classes = formStyles();
    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Typography variant="h6" className={classes.title}>
                        Image
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Box
                        {...getRootProps({className: 'dropzone'})}
                        className={classes.imageContainer}
                    >
                        <input {...getInputProps()} />
                        {state.image.value && state.image.value !== '' ? (
                            <img
                                alt="coin"
                                src={state.image.value}
                                className={classes.imageContent}
                            />
                        ) : (
                            <p>
                                Drag and drop file here, or click to select file
                            </p>
                        )}
                    </Box>
                    {state.image.error ? (
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                            {state.image.error}
                        </p>
                    ) : null}
                </Grid>
                <Grid item md={12}>
                    <Typography variant="h6" className={classes.title}>
                        General
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item md={8}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    value={state.name.value}
                                    error={state.name.error !== ''}
                                    helperText={state.name.error}
                                    onChange={(e) => onChangeState('name', e)}
                                    label="Name"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    value={state.code.value}
                                    error={state.code.error !== ''}
                                    helperText={state.code.error}
                                    onChange={(e) => onChangeState('code', e)}
                                    label="Code"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item md={4}>
                            <FormControl
                                variant="outlined"
                                className={classes.formGroup}
                            >
                                <InputLabel
                                    shrink
                                    error={state.powerUnit.error !== ''}
                                >
                                    Power Unit
                                </InputLabel>
                                <Select
                                    label="Power Unit"
                                    value={state.powerUnit.value}
                                    onChange={(e) =>
                                        onChangeState('powerUnit', e)
                                    }
                                >
                                    {POWER_UNITS.map((powerUnit, index) => (
                                        <MenuItem key={index} value={powerUnit}>
                                            {powerUnit}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    {state.powerUnit.error}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    value={state.algorithm.value}
                                    error={state.algorithm.error !== ''}
                                    helperText={state.algorithm.error}
                                    onChange={(e) =>
                                        onChangeState('algorithm', e)
                                    }
                                    label="Algorithm"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    value={state.createWalletUrl.value}
                                    error={state.createWalletUrl.error !== ''}
                                    helperText={state.createWalletUrl.error}
                                    onChange={(e) =>
                                        onChangeState('createWalletUrl', e)
                                    }
                                    label="Create Wallet Url"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item md={4}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    label="Decimal"
                                    type="number"
                                    value={state.decimals.value}
                                    error={state.decimals.error !== ''}
                                    helperText={state.decimals.error}
                                    onChange={(e) =>
                                        onChangeState('decimals', e)
                                    }
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    label="Withdraw Fee"
                                    type="number"
                                    value={state.feeWithdraw.value}
                                    error={state.feeWithdraw.error !== ''}
                                    helperText={state.feeWithdraw.error}
                                    onChange={(e) =>
                                        onChangeState('feeWithdraw', e)
                                    }
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    label="Minimum Withdraw"
                                    type="number"
                                    value={state.minimumWithdraw.value}
                                    error={state.minimumWithdraw.error !== ''}
                                    helperText={state.minimumWithdraw.error}
                                    onChange={(e) =>
                                        onChangeState('minimumWithdraw', e)
                                    }
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item md={4}>
                            <FormControl
                                variant="outlined"
                                className={classes.formGroup}
                            >
                                <InputLabel
                                    shrink
                                    error={state.frozenFlag.error !== ''}
                                >
                                    Is Frozen
                                </InputLabel>
                                <Select
                                    label="Is Frozen"
                                    value={state.frozenFlag.value}
                                    onChange={(e) =>
                                        onChangeState('frozenFlag', e)
                                    }
                                >
                                    <MenuItem value={0}>No</MenuItem>
                                    <MenuItem value={1}>Yes</MenuItem>
                                </Select>
                                <FormHelperText>
                                    {state.frozenFlag.error}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl
                                variant="outlined"
                                className={classes.formGroup}
                            >
                                <InputLabel
                                    shrink
                                    error={state.actFlag.error !== ''}
                                >
                                    Is Active
                                </InputLabel>
                                <Select
                                    label="Is Active"
                                    value={state.actFlag.value}
                                    onChange={(e) =>
                                        onChangeState('actFlag', e)
                                    }
                                >
                                    <MenuItem value={0}>No</MenuItem>
                                    <MenuItem value={1}>Yes</MenuItem>
                                </Select>
                                <FormHelperText>
                                    {state.actFlag.error}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl
                                variant="outlined"
                                className={classes.formGroup}
                            >
                                <InputLabel
                                    shrink
                                    error={state.actFlag.error !== ''}
                                >
                                    Is Mining
                                </InputLabel>
                                <Select
                                    label="Is Mining"
                                    value={state.miningFlag.value}
                                    onChange={(e) =>
                                        onChangeState('miningFlag', e)
                                    }
                                >
                                    <MenuItem value={0}>No</MenuItem>
                                    <MenuItem value={1}>Yes</MenuItem>
                                </Select>
                                <FormHelperText>
                                    {state.miningFlag.error}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
});

export default CoinForm;
