import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
    comboBox: {
        width: '100%',
        '& .MuiAutocomplete-inputRoot': {
            padding: 2,
        },
    },
});

interface IProps {
    label: string;
    freeSolo?: boolean;
    options: IComboBoxOption[];
    onChange: (value: string | number) => void;
    value: any;
    errorText?: any;
    isError?: boolean;
}
export interface IComboBoxOption {
    label: string;
    value: string | number;
}

const ComboBox = (props: IProps) => {
    const classes = useStyles();
    const {
        options,
        label,
        freeSolo,
        value,
        onChange,
        errorText,
        isError,
        ...others
    } = props;
    return (
        <Autocomplete
            freeSolo={freeSolo}
            id="combo-box-demo"
            options={options}
            inputValue={value}
            onInputChange={(_event: React.ChangeEvent<any>, newInputValue) => {
                onChange(newInputValue);
            }}
            getOptionLabel={(option) => option.label}
            className={classes.comboBox}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={label}
                    error={isError}
                    helperText={errorText}
                    variant="outlined"
                />
            )}
            {...others}
        />
    );
};
export default ComboBox;
