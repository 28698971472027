import React, {useState} from 'react';
import {Grid, Button} from '@material-ui/core';
import {find} from 'lodash';
import {Input} from '../../components/Input';
import {changePasswordMiddleware} from '../../middlewares/setting';

export const ChangePassword = () => {
    const [state, setState] = useState({
        password: {value: '', error: ''},
        repassword: {value: '', error: ''},
    });

    const onChangeText = (key: string) => (event: React.ChangeEvent<any>) => {
        if (key === 'password') {
            setState({
                ...state,
                password: {
                    error:
                        event.target.value.length < 6
                            ? 'Password is too short'
                            : '',
                    value: event.target.value,
                },
                repassword: {
                    error: !state.repassword.value
                        ? ''
                        : event.target.value.length < 6
                        ? 'Confirm password is too short'
                        : event.target.value !== state.repassword.value
                        ? 'Password is not match'
                        : '',
                    value: state.repassword.value,
                },
            });
        }
        if (key === 'repassword') {
            setState({
                ...state,
                password: {
                    error:
                        state.password.value.length < 6
                            ? 'Password is too short'
                            : '',
                    value: state.password.value,
                },
                repassword: {
                    error: !state.repassword.value
                        ? ''
                        : event.target.value.length < 6
                        ? 'Confirm password is too short'
                        : event.target.value !== state.password.value
                        ? 'Password not match'
                        : '',
                    value: event.target.value,
                },
            });
        }
    };

    const resetState = () =>
        setState({
            password: {value: '', error: ''},
            repassword: {value: '', error: ''},
        });

    const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && disableSubmit === false) {
            event.preventDefault();
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        changePasswordMiddleware(state.password.value, () => {
            resetState();
        });
    };

    const disableSubmit = Boolean(
        find(state, (el) => el.value === '' || el.error !== ''),
    );

    return (
        <Grid container>
            <Grid container style={{maxWidth: 400}}>
                <Input
                    fullWidth
                    label="Password"
                    type="password"
                    value={state.password.value}
                    helperText={state.password.error}
                    error={state.password.error ? true : false}
                    onChange={onChangeText('password')}
                    onKeyPress={onKeyPress}
                />

                <Input
                    fullWidth
                    label="Confirm password"
                    type="password"
                    value={state.repassword.value}
                    helperText={state.repassword.error}
                    error={state.repassword.error ? true : false}
                    onChange={onChangeText('repassword')}
                    onKeyPress={onKeyPress}
                />
            </Grid>

            <Grid container justify="center" style={{marginTop: 30}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={disableSubmit}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};
