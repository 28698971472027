import {Action} from 'redux';
import {IUser, IUserDataShow, IUserPlan, IUserBalance} from '../types/user';
import {IWithdraw} from '../types/withdraw';

////////////////////////////////////////////////////////
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export type GET_USERS_REQUEST = typeof GET_USERS_REQUEST;

export interface IGetUsersRequestAction extends Action<GET_USERS_REQUEST> {}
export const getUsersRequest = (): IGetUsersRequestAction => {
    return {
        type: GET_USERS_REQUEST,
    };
};

////////////////////////////////////////////////////////
export const SET_USERS_RESPONSE = 'SET_USERS_RESPONSE';
export type SET_USERS_RESPONSE = typeof SET_USERS_RESPONSE;

export interface ISetUsersResponseAction extends Action<SET_USERS_RESPONSE> {
    payload: IUser;
}

export const setUsers = (payload: IUser): ISetUsersResponseAction => {
    return {
        type: SET_USERS_RESPONSE,
        payload,
    };
};

////////////////////////////////////////////////////////
export const SET_USER_SHOW = 'SET_USER_SHOW';
export type SET_USER_SHOW = typeof SET_USER_SHOW;

export interface ISetUserShowResponseAction extends Action<SET_USER_SHOW> {
    payload: IUserDataShow;
}

export const setUserShow = (
    payload: IUserDataShow,
): ISetUserShowResponseAction => {
    return {
        type: SET_USER_SHOW,
        payload,
    };
};

////////////////////////////////////////////////////////
export const GET_USER_PLAN_REQUEST = 'GET_USER_PLAN_REQUEST';
export type GET_USER_PLAN_REQUEST = typeof GET_USER_PLAN_REQUEST;

export interface IGetUserPlanRequestAction
    extends Action<GET_USER_PLAN_REQUEST> {}
export const getUserPlanRequest = (): IGetUserPlanRequestAction => {
    return {
        type: GET_USER_PLAN_REQUEST,
    };
};

////////////////////////////////////////////////////////
export const SET_USER_PLAN_RESPONSE = 'SET_USER_PLAN_RESPONSE';
export type SET_USER_PLAN_RESPONSE = typeof SET_USER_PLAN_RESPONSE;

export interface ISetUserPlanResponseAction
    extends Action<SET_USER_PLAN_RESPONSE> {
    payload: IUserPlan;
}

export const setUserPlans = (
    payload: IUserPlan,
): ISetUserPlanResponseAction => {
    return {
        type: SET_USER_PLAN_RESPONSE,
        payload,
    };
};

////////////////////////////////////////////////////////
export const GET_USER_WITHDRAW_REQUEST = 'GET_USER_WITHDRAW_REQUEST';
export type GET_USER_WITHDRAW_REQUEST = typeof GET_USER_WITHDRAW_REQUEST;

export interface IGetUserWithdrawRequestAction
    extends Action<GET_USER_WITHDRAW_REQUEST> {}
export const getUserWithdrawRequest = (): IGetUserWithdrawRequestAction => {
    return {
        type: GET_USER_WITHDRAW_REQUEST,
    };
};

////////////////////////////////////////////////////////
export const SET_USER_WITHDRAW_RESPONSE = 'SET_USER_WITHDRAW_RESPONSE';
export type SET_USER_WITHDRAW_RESPONSE = typeof SET_USER_WITHDRAW_RESPONSE;

export interface ISetUserWithdrawResponseAction
    extends Action<SET_USER_WITHDRAW_RESPONSE> {
    payload: IWithdraw;
}

export const setUserWithdraw = (
    payload: IWithdraw,
): ISetUserWithdrawResponseAction => {
    return {
        type: SET_USER_WITHDRAW_RESPONSE,
        payload,
    };
};

////////////////////////////////////////////////////////
export const SET_USER_BALANCE_RESPONSE = 'SET_USER_BALANCE_RESPONSE';
export type SET_USER_BALANCE_RESPONSE = typeof SET_USER_BALANCE_RESPONSE;

export interface ISetUserBalanceResponseAction
    extends Action<SET_USER_BALANCE_RESPONSE> {
    payload: IUserBalance[];
}

export const setUserBalance = (
    payload: IUserBalance[],
): ISetUserBalanceResponseAction => {
    return {
        type: SET_USER_BALANCE_RESPONSE,
        payload,
    };
};
