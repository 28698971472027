import {store} from '..';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {ICoin, ICoinList, ICoinRequest, IOverviewCoin} from '../types/coin';
import {IPaginationRequest} from '../types/pagination';
import {
    setCoins,
    getCoinListRequest,
    setCoinList,
    setOverviewCoins,
} from '../actions/coin';
import {
    fireErrorNotification,
    fireSuccessNotification,
} from '../helpers/notification';

export const getCoins = () => {
    Axios.get('/Coin')
        .then((response: AxiosResponse<ICoin[]>) => {
            store.dispatch(setCoins(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
export const getMiningCoins = () => {
    Axios.get('/Coin/getMiningCoin')
        .then((response: AxiosResponse<ICoin[]>) => {
            store.dispatch(setCoins(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const getCoinsByPagination = (params: IPaginationRequest) => {
    const {pageNumber, pageSize} = params;
    store.dispatch(getCoinListRequest());
    ///api/Plan/GetPlans
    Axios.get(`/Coin/GetCoins?PageNumber=${pageNumber}&PageSize=${pageSize}`)
        .then((response: AxiosResponse<ICoinList>) => {
            store.dispatch(setCoinList(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const addCoin = (params: ICoinRequest) => {
    Axios.post('/Coin', params)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const editCoin = (params: ICoinRequest, id: string) => {
    Axios.put(`/Coin/${id}`, params)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const getTotalBalances = () => {
    Axios.get('/Coin/GetTotalBalance')
        .then((response: AxiosResponse<{data: IOverviewCoin[]}>) => {
            store.dispatch(setOverviewCoins(response.data.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
