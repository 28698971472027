import {IWithdraw} from '../types/withdraw';
import {
    GET_WITHDRAWS_REQUEST,
    IGetWithdrawsRequestAction,
    SET_WITHDRAWS_RESPONSE,
    ISetWithdrawsResponseAction,
} from '../actions/withdraw';
import {DEFAULT_PAGE_SIZE} from '../constants/pagination';

const initState: IWithdraw = {
    data: [],
    pageInfo: {
        totalCount: 0,
        currentPage: 1,
        totalPages: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        searchText: '',
    },
    isLoading: false,
};

export const withdraw = (
    state: IWithdraw = initState,
    action: ISetWithdrawsResponseAction | IGetWithdrawsRequestAction,
) => {
    if (action.type === GET_WITHDRAWS_REQUEST) {
        return {
            ...state,
            isLoading: true,
        };
    }

    if (action.type === SET_WITHDRAWS_RESPONSE) {
        return {
            ...state,
            data: action.payload.data,
            pageInfo: action.payload.pageInfo,
            isLoading: false,
        };
    }
    return state;
};
