import React from 'react';
import {PageLayout} from '../../components/PageLayout';
import {makeStyles, Box} from '@material-ui/core';
// import {pushTo} from '../../helpers/history';
// import {PATH} from '../../constants/path';
// import {useSelector} from 'react-redux';
// import {StoreState} from '../../types/store-state';

const useStyles = makeStyles({
    contentWrapper: {
        width: '100%',
        '& .MuiButton-startIcon': {
            marginRight: -10,
        },
    },
});

export const Dashboard = () => {
    const classes = useStyles();

    return (
        <PageLayout>
            <Box className={classes.contentWrapper}></Box>
        </PageLayout>
    );
};
