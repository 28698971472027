import React, {useState, useEffect} from 'react';
import {Grid, MenuItem, Button} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {UserProfile} from '../../types/setting';
import {Input} from '../../components/Input';
import {
    isValidEmail,
    generateNumberArray,
    daysInMonth,
} from '../../helpers/validate';
import {isEqual} from 'lodash';
import {updateUserProfileMiddleware} from '../../middlewares/setting';
import {Checkbox} from '../../components/Checkbox';

const DATE_SELECTION = {
    day: generateNumberArray(1, 31),
    month: generateNumberArray(1, 12),
    year: generateNumberArray(
        new Date().getFullYear() - 13,
        new Date().getFullYear() - 100,
    ),
};

export const GeneralInformation = () => {
    const {userProfile, currencies} = useSelector((state: StoreState) => state);

    const [user, setUser] = useState<UserProfile | null>(null);
    const [emailError, setEmailError] = useState('');
    const [dayError, setDayError] = useState('');

    useEffect(() => {
        if (userProfile) {
            setUser(userProfile);
        }
        // eslint-disable-next-line
    }, [userProfile]);

    useEffect(() => {
        if (user && daysInMonth(user.month, user.year) < user.day) {
            setDayError('Day is invalid');
        } else {
            setDayError('');
        }
        // eslint-disable-next-line
    }, [user?.day, user?.month, user?.year]);

    const onChangeInput = (key: string) => (event: any, value?: any) => {
        if (key === 'backupEmail') {
            setEmailError(
                isValidEmail(event.target.value) ? '' : 'Email is invalid',
            );
        }

        if (user) {
            setUser({
                ...user,
                [key]: event.target.value || value,
            });
        }
    };

    const handleSubmit = () => {
        if (user) {
            updateUserProfileMiddleware(user);
        }
    };

    const isDisableSubmit =
        isEqual(user, userProfile) || emailError !== '' || dayError !== '';

    return (
        <Grid container>
            <Grid item xs={6} style={{paddingRight: 20}}>
                <Input
                    fullWidth
                    disabled
                    value={user?.email || ''}
                    label="Email"
                />
            </Grid>
            <Grid item xs={6} style={{paddingLeft: 20}}>
                <Input
                    fullWidth
                    value={user?.backupEmail || ''}
                    onChange={onChangeInput('backupEmail')}
                    error={emailError ? true : false}
                    helperText={emailError}
                    label="Backup Email"
                />
            </Grid>

            <Grid item xs={6} style={{paddingRight: 20}}>
                <Input
                    fullWidth
                    value={user?.firstName || ''}
                    onChange={onChangeInput('firstName')}
                    label="First Name"
                />
            </Grid>
            <Grid item xs={6} style={{paddingLeft: 20}}>
                <Input
                    fullWidth
                    value={user?.lastName || ''}
                    onChange={onChangeInput('lastName')}
                    label="Last Name"
                />
            </Grid>

            <Grid item xs={6} style={{paddingRight: 20}}>
                <Grid container>
                    <Grid item xs={4} style={{paddingRight: 10}}>
                        <Input
                            fullWidth
                            value={user?.day || ''}
                            onChange={onChangeInput('day')}
                            label="Day"
                            error={dayError ? true : false}
                            helperText={dayError}
                            select
                        >
                            <MenuItem key={0} value={0}>
                                Select Day Of Birth
                            </MenuItem>
                            {DATE_SELECTION.day.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Input>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{paddingRight: 10, paddingLeft: 10}}
                    >
                        <Input
                            fullWidth
                            value={user?.month || ''}
                            onChange={onChangeInput('month')}
                            label="Month"
                            select
                        >
                            <MenuItem key={0} value={0}>
                                Select Month Of Birth
                            </MenuItem>
                            {DATE_SELECTION.month.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Input>
                    </Grid>
                    <Grid item xs={4} style={{paddingLeft: 10}}>
                        <Input
                            fullWidth
                            value={user?.year || ''}
                            onChange={onChangeInput('year')}
                            label="Year"
                            select
                        >
                            <MenuItem value={0}>Select Year Of Birth</MenuItem>
                            {DATE_SELECTION.year.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Input>
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            fullWidth
                            value={user?.currencyId || 0}
                            onChange={onChangeInput('currencyId')}
                            label="Currency"
                            select
                        >
                            <MenuItem value={0}>Select currency</MenuItem>
                            {currencies.map((currency, index) => (
                                <MenuItem key={index} value={currency.id}>
                                    {currency.code}
                                </MenuItem>
                            ))}
                        </Input>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} style={{paddingLeft: 20}}>
                <Input
                    fullWidth
                    value={user?.phoneNumber || ''}
                    onChange={onChangeInput('phoneNumber')}
                    label="Phone Number"
                />
            </Grid>

            {user && (
                <Grid item xs={6} style={{paddingLeft: 20}}>
                    <Checkbox
                        label="Receive our Newsletter?"
                        checked={user.subscribe}
                        onChange={onChangeInput('subscribe')}
                    />
                </Grid>
            )}

            <Grid container justify="center" style={{marginTop: 30}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isDisableSubmit}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};
