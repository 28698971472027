import React, {useState} from 'react';
import {PageLayout} from '../../components/PageLayout';
import {makeStyles, Box, Link} from '@material-ui/core';
import Table from '../../components/Table';
import {pushTo} from '../../helpers/history';
import {PATH} from '../../constants/path';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {IWithdrawData, IWithdrawAction} from '../../types/withdraw';
import {
    getWithdrawList,
    approveWithdraw,
    rejectWithdraw,
} from '../../middlewares/withdraw';
import moment from 'moment';
import {
    WITHDRAW_HISTORY_STATUS_NAMES,
    WITHDRAW_CREATED_STATUS,
} from '../../constants/withdraw';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Swal from 'sweetalert2';

const useStyles = makeStyles({
    contentWrapper: {
        width: '100%',
        '& .MuiButton-startIcon': {
            marginRight: -10,
        },
    },
    pointer: {
        cursor: 'pointer',
    },
});

export const Withdraw = () => {
    const {withdraw, userProfile} = useSelector((state: StoreState) => state);
    const classes = useStyles();
    const [columns] = useState([
        {
            title: 'User Id',
            field: 'userId',
            render: (rowData: IWithdrawData) => {
                return (
                    <Link
                        className={classes.pointer}
                        onClick={() =>
                            pushTo(PATH.userShow, {id: rowData.userId})
                        }
                    >
                        {rowData.userId}
                    </Link>
                );
            },
        },
        {
            title: 'Address',
            field: 'walletAddress',
        },
        {
            title: 'Amount',
            field: 'amount',
            render: (rowData: IWithdrawData) => {
                return `${rowData.amount} ${rowData.coin.code}`;
            },
        },
        {
            title: 'Fee',
            field: 'withdrawFee',
            render: (rowData: IWithdrawData) => {
                return `${rowData.withdrawFee} ${rowData.coin.code}`;
            },
        },
        {
            title: 'Status',
            field: 'status',
            render: (rowData: IWithdrawData) => {
                return WITHDRAW_HISTORY_STATUS_NAMES[rowData.status];
            },
        },
        {
            title: 'Created At',
            field: 'createAt',
            render: (rowData: IWithdrawData) => {
                return moment(rowData.createAt).format('LLLL');
            },
        },
    ]);

    const prepareWithdrawApproveData = (
        _event: any,
        rowData: IWithdrawData,
    ) => {
        Swal.fire({
            title: 'Do you want to approve this withdraw?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: `Approve`,
        }).then((result) => {
            if (result.isConfirmed) {
                return handleConfirmWithdraw(approveWithdraw, rowData);
            }
        });
    };

    const prepareWithdrawRejectData = (_event: any, rowData: IWithdrawData) => {
        Swal.fire({
            title: 'Do you want to reject this withdraw?',
            icon: 'question',
            showConfirmButton: false,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: `Reject`,
        }).then((result) => {
            if (result.isDenied) {
                return handleConfirmWithdraw(rejectWithdraw, rowData);
            }
        });
    };

    const handleConfirmWithdraw = (
        action: (params: IWithdrawAction, id: string) => void,
        rowData: IWithdrawData,
    ) => {
        let formHtml =
            '<input id="withdraw-note" class="swal2-input" placeholder="Note">';
        if (userProfile?.twoFactorEnabled) {
            formHtml +=
                '<input id="withdraw-twoFactorCode" class="swal2-input" placeholder="Google 2FA code">';
        }
        Swal.fire({
            title: 'Enter Note',
            icon: 'info',
            html: formHtml,
            focusConfirm: false,
            showCancelButton: true,
            preConfirm: () => {
                let note = '';
                let twoFactorCode = '';
                const noteElement: any = document.getElementById(
                    'withdraw-note',
                );
                if (userProfile?.twoFactorEnabled) {
                    const twoFactorCodeElement: any = document.getElementById(
                        'withdraw-twoFactorCode',
                    );
                    twoFactorCode = twoFactorCodeElement.value;
                }
                note = noteElement.value;
                // call action
                action({note, twoFactorCode}, rowData.id);
            },
        });
    };

    return (
        <PageLayout>
            <Box className={classes.contentWrapper}>
                <Table
                    headers={columns}
                    data={withdraw.data}
                    loadAction={getWithdrawList}
                    page={withdraw.pageInfo.currentPage}
                    limit={withdraw.pageInfo.pageSize}
                    count={withdraw.pageInfo.totalCount}
                    title="Withdraw"
                    isLoading={withdraw.isLoading}
                    path={PATH.withdraw}
                    searchText={withdraw.pageInfo.searchText}
                    actions={[
                        {
                            icon: <CheckCircleIcon />,
                            tooltip: 'Approve Withdraw',
                            onClick: prepareWithdrawApproveData,
                            isHidden: (rowData: IWithdrawData) => {
                                if (
                                    rowData.status === WITHDRAW_CREATED_STATUS
                                ) {
                                    return false;
                                }
                                return true;
                            },
                        },
                        {
                            icon: <RemoveCircleIcon />,
                            tooltip: 'Reject Withdraw',
                            onClick: prepareWithdrawRejectData,
                            isHidden: (rowData: IWithdrawData) => {
                                if (
                                    rowData.status === WITHDRAW_CREATED_STATUS
                                ) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ]}
                />
            </Box>
        </PageLayout>
    );
};
