import React, {useEffect, useState} from 'react';
import {PageLayout} from '../../components/PageLayout';
import {
    makeStyles,
    Box,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    TextField,
    FormHelperText,
    Button,
} from '@material-ui/core';
import BasicTable from '../../components/BasicTable';
import Autocomplete from '../../components/Autocomplete';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {IOverviewCoin} from '../../types/coin';
import {IWithdrawTransferAll} from '../../types/withdraw';
import {getTotalBalances} from '../../middlewares/coin';
import {transferAll} from '../../middlewares/withdraw';

import {isEmpty, has, map} from 'lodash';

const useStyles = makeStyles({
    contentWrapper: {
        width: '100%',
        '& .withdraw-panel': {
            padding: '40px 20px',
            boxShadow: '0px 0px 30px 0px rgba(82, 63, 105, 0.05)',
            borderRadius: 5,
            backgroundColor: '#FFF',
            '& .action-box': {
                textAlign: 'right',
            },
        },
    },
    title: {
        margin: '30px 0',
    },
    greenText: {
        color: 'green',
    },
    redText: {
        color: 'red',
    },
    formGroup: {
        marginBottom: 20,
        width: '100%',
        // maxWidth: 500,
    },
    bagde: {
        fontSize: 13,
        backgroundColor: '#f0b90a',
        marginRight: 15,
        padding: '2px 5px',
        borderRadius: 5,
        color: '#FFF',
        cursor: 'pointer',
    },
});

export const AggregateBalance = () => {
    const classes = useStyles();
    const {overviewCoins, coins, userProfile} = useSelector(
        (state: StoreState) => state,
    );
    const [columns] = useState([
        {
            name: 'coin',
            title: 'Coin',
            renderer: (rowData: IOverviewCoin) => {
                return rowData.coin.name;
            },
        },
        {
            name: 'adminBalance',
            title: 'Admin Balance',
            renderer: (rowData: IOverviewCoin) => {
                if (rowData.adminBalance > rowData.userBalance) {
                    return (
                        <span className={classes.greenText}>
                            {rowData.adminBalance.toFixed(
                                rowData.coin.decimals,
                            )}{' '}
                            {rowData.coin.code}
                        </span>
                    );
                }
                return (
                    <span className={classes.redText}>
                        {rowData.adminBalance.toFixed(rowData.coin.decimals)}{' '}
                        {rowData.coin.code}
                    </span>
                );
            },
        },
        {
            name: 'userBalance',
            title: 'Total User Balance',
            renderer: (rowData: IOverviewCoin) => {
                return `${rowData.userBalance.toFixed(rowData.coin.decimals)} ${
                    rowData.coin.code
                }`;
            },
        },
    ]);

    const [state, setState] = useState({
        coinId: {value: '', error: ''},
        amount: {value: 0, error: ''},
        walletAddress: {value: '', error: ''},
        twoFactorCode: {value: '', error: ''},
    });

    const onChangeState = (key: string, event: React.ChangeEvent<any>) => {
        event.persist();
        let value = event.target.value;
        if (key === 'coinId') {
            setState({
                ...state,
                amount: {
                    value: 0,
                    error: '',
                },
                coinId: {
                    value: value,
                    error: '',
                },
            });
            return;
        }

        // check if value is amount
        if (!isNaN(parseFloat(value))) {
            if (value < 0) {
                value = 0;
            }
        }

        setState({
            ...state,
            [key]: {
                value: value,
                error: '',
            },
        });
    };

    const setMaxAmount = () => {
        const aggregateBalance = overviewCoins.find(
            (overviewCoin) => overviewCoin.coin.id === state.coinId.value,
        );
        if (aggregateBalance) {
            setState({
                ...state,
                amount: {
                    value: aggregateBalance.adminBalance,
                    error: '',
                },
            });
        }
    };

    const onChangeAddress = (value: string | number) => {
        setState({
            ...state,
            walletAddress: {
                value: value.toString(),
                error: '',
            },
        });
    };

    const handleSubmit = () => {
        if (!validateData()) {
            return; /// do nothing
        }
        const postData: IWithdrawTransferAll = {
            amount: state.amount.value,
            coinId: state.coinId.value,
            walletAddress: state.walletAddress.value,
            twoFactorCode: state.twoFactorCode.value,
        };
        transferAll(postData);

        // reset input field
        setState({
            coinId: {value: '', error: ''},
            amount: {value: 0, error: ''},
            walletAddress: {value: '', error: ''},
            twoFactorCode: {value: '', error: ''},
        });
    };

    useEffect(() => {
        getTotalBalances();
    }, []);

    useEffect(() => {
        if (!isEmpty(coins)) {
            const firstCoin = coins[0];
            if (firstCoin) {
                setState({
                    ...state,
                    coinId: {
                        value: firstCoin.id,
                        error: '',
                    },
                });
            }
        }
    }, [coins]);

    const validateData = () => {
        let newState = {...state};

        let isValid = true;
        map(newState, (attribute: any, key: string) => {
            if (has(attribute, 'value')) {
                if (key === 'coinId') {
                    if (attribute.value === '') {
                        isValid = false;
                        attribute.error = `Coin can not be empty`;
                    }
                }
                if (key === 'amount') {
                    const aggregateBalance = overviewCoins.find(
                        (overviewCoin) =>
                            overviewCoin.coin.id === state.coinId.value,
                    );
                    if (!aggregateBalance) {
                        isValid = false;
                        attribute.error = `Amount can not greater than Admin balance`;
                    } else if (
                        aggregateBalance.adminBalance < attribute.value
                    ) {
                        isValid = false;
                        attribute.error = `Amount can not greater than ${aggregateBalance.adminBalance}`;
                    } else if (attribute.value === 0) {
                        isValid = false;
                        attribute.error = `Amount can not be zero`;
                    }
                }

                if (key === 'walletAddress') {
                    if (attribute.value === '') {
                        isValid = false;
                        attribute.error = `Please input your wallet address`;
                    }
                }
                if (key === 'twoFactorCode') {
                    if (userProfile && userProfile.twoFactorEnabled) {
                        if (attribute.value === '') {
                            isValid = false;
                            attribute.error = `Please input Google 2FA code`;
                        }
                    }
                }
                return attribute;
            }
        });
        setState(newState);
        return isValid;
    };

    return (
        <PageLayout>
            <Box className={classes.contentWrapper}>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Typography variant="h4" className={classes.title}>
                            Aggregate Balance
                        </Typography>
                        <BasicTable
                            columns={columns}
                            data={overviewCoins}
                            noDataTitle="No data found"
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant="h4" className={classes.title}>
                            Withdraw
                        </Typography>
                        <Box className="withdraw-panel">
                            <Box>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formGroup}
                                >
                                    <InputLabel
                                        shrink
                                        error={state.coinId.error !== ''}
                                    >
                                        Coin
                                    </InputLabel>
                                    <Select
                                        label="coin"
                                        value={state.coinId.value}
                                        onChange={(e) =>
                                            onChangeState('coinId', e)
                                        }
                                    >
                                        {coins.map((coin, index) => (
                                            <MenuItem
                                                key={index}
                                                value={coin.id}
                                            >
                                                {coin.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>
                                        {state.coinId.error}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formGroup}
                                >
                                    <TextField
                                        label="Amount"
                                        type="number"
                                        value={state.amount.value}
                                        error={state.amount.error !== ''}
                                        helperText={state.amount.error}
                                        onChange={(e) =>
                                            onChangeState('amount', e)
                                        }
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <span
                                                        className={
                                                            classes.bagde
                                                        }
                                                        onClick={setMaxAmount}
                                                    >
                                                        Max
                                                    </span>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formGroup}
                                >
                                    <Autocomplete
                                        label={`Recipient's address`}
                                        freeSolo
                                        onChange={onChangeAddress}
                                        value={state.walletAddress.value}
                                        options={[]}
                                        isError={
                                            state.walletAddress.error !== ''
                                        }
                                        errorText={state.walletAddress.error}
                                    />
                                </FormControl>
                            </Box>
                            {userProfile && userProfile.twoFactorEnabled ? (
                                <Box>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formGroup}
                                    >
                                        <TextField
                                            label="Google 2FA code"
                                            value={state.twoFactorCode.value}
                                            error={
                                                state.twoFactorCode.error !== ''
                                            }
                                            helperText={
                                                state.twoFactorCode.error
                                            }
                                            onChange={(e) =>
                                                onChangeState(
                                                    'twoFactorCode',
                                                    e,
                                                )
                                            }
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            ) : null}

                            <Box className="action-box">
                                <Button
                                    onClick={handleSubmit}
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </PageLayout>
    );
};
