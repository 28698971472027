import React from 'react';
import {TextField, OutlinedTextFieldProps, makeStyles} from '@material-ui/core';

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {}

const styles = makeStyles({
    autofill: {
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            height: 40,
            padding: '0 12px',
        },
    },
});

export const Input = (props: Props) => {
    const {style, InputProps, ...other} = props;
    const classes = styles();

    return (
        <TextField
            variant="outlined"
            InputProps={{
                style: {
                    height: 40,
                    backgroundColor: '#EEF0F7',
                },
                ...InputProps,
            }}
            style={{
                height: 70,
                ...style,
            }}
            className={classes.autofill}
            {...other}
        />
    );
};
