import React, {useState} from 'react';
import {
    createStyles,
    Theme,
    withStyles,
    WithStyles,
    makeStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog, {DialogProps} from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const useStyles = makeStyles({
    closeButton: {
        marginRight: 5,
    },
});

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((_theme: Theme) => ({
    root: {
        padding: 16,
    },
}))(MuiDialogContent);

const DialogActions = withStyles((_theme: Theme) => ({
    root: {
        margin: 0,
        padding: 16,
    },
}))(MuiDialogActions);

interface IModalProps {
    open: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
    title: string;
    children: any;
    hasClose?: boolean;
    maxWidth?:
        | false
        | 'xs'
        | 'sm'
        | 'md'
        | 'lg'
        | 'xl'
        | (() => false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined)
        | undefined;
}

const Modal = (props: IModalProps) => {
    const {
        open,
        handleClose,
        handleSubmit,
        title,
        children,
        hasClose,
        maxWidth,
    } = props;
    const [size] = useState<DialogProps['maxWidth']>(
        maxWidth ? maxWidth : 'sm',
    );
    const classes = useStyles();

    return (
        <Dialog
            fullWidth={true}
            onClose={handleClose}
            maxWidth={size}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title}
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions>
                {hasClose ? (
                    <Button
                        className={classes.closeButton}
                        onClick={handleClose}
                        variant="contained"
                        color="secondary"
                        disableElevation
                    >
                        Close
                    </Button>
                ) : null}
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    disableElevation
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default Modal;
