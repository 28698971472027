import {Action} from 'redux';
import {IDiscountList, IDiscount} from '../types/discount';

////////////////////////////////////////////////////////
export const GET_DISCOUNT_LIST_REQUEST = 'GET_DISCOUNT_LIST_REQUEST';
export type GET_DISCOUNT_LIST_REQUEST = typeof GET_DISCOUNT_LIST_REQUEST;

export interface IGetDiscountListRequestAction
    extends Action<GET_DISCOUNT_LIST_REQUEST> {}
export const getDiscountListRequest = (): IGetDiscountListRequestAction => {
    return {
        type: GET_DISCOUNT_LIST_REQUEST,
    };
};

////////////////////////////////////////////////////////
export const SET_DISCOUNT_LIST_RESPONSE = 'SET_DISCOUNT_LIST_RESPONSE';
export type SET_DISCOUNT_LIST_RESPONSE = typeof SET_DISCOUNT_LIST_RESPONSE;

export interface ISetDiscountListResponseAction
    extends Action<SET_DISCOUNT_LIST_RESPONSE> {
    payload: IDiscountList;
}

export const setDiscountList = (
    payload: IDiscountList,
): ISetDiscountListResponseAction => {
    return {
        type: SET_DISCOUNT_LIST_RESPONSE,
        payload,
    };
};

export const SET_DISCOUNT = 'SET_DISCOUNT';
export type SET_DISCOUNT = typeof SET_DISCOUNT;

export interface ISetDiscountAction extends Action<SET_DISCOUNT> {
    payload: IDiscount[];
}

export const setDiscounts = (payload: IDiscount[]): ISetDiscountAction => {
    return {
        type: SET_DISCOUNT,
        payload,
    };
};
