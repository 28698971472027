import React, {useEffect, useState} from 'react';
import {PageLayout} from '../../components/PageLayout';
import {
    makeStyles,
    Box,
    Typography,
    IconButton,
    Grid,
    Avatar,
    Tooltip,
} from '@material-ui/core';
import {useParams} from 'react-router-dom';
import {
    getUserShow,
    getUserPlans,
    getWithdrawHistoryOfUser,
    getUserBalances,
} from '../../middlewares/user';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import Table from '../../components/Table';
import {IPaginationRequest} from '../../types/pagination';
import {IUserPlanData} from '../../types/user';
import {IWithdrawData} from '../../types/withdraw';
import moment from 'moment';
import {isEmpty} from 'lodash';
import {WITHDRAW_HISTORY_STATUS_NAMES} from '../../constants/withdraw';
//
import DateRangeIcon from '@material-ui/icons/DateRange';
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

const useStyles = makeStyles({
    contentWrapper: {
        width: '100%',
        '& .MuiButton-startIcon': {
            marginRight: -10,
        },
    },
    gridContainer: {
        height: 'max-content',
        padding: '0 30px',
    },
    panel: {
        boxShadow: '0px 0px 30px 0px rgba(82, 63, 105, 0.05)',
        padding: 15,
        borderRadius: 5,
        backgroundColor: '#FFFFFF',
    },
    generalInformation: {
        marginTop: 20,
    },
    profileTop: {
        marginTop: 50,
        marginBottom: 138,
    },
    avatarContainer: {
        width: 125,
        height: 125,
        margin: 'auto',
        backgroundColor: '#1BC5BD',
        padding: 20,
    },
    profileUserName: {
        fontWeight: 600,
        textAlign: 'center',
        marginTop: 10,
        color: '#3F4254',
    },
    profileEmail: {
        textAlign: 'center',
        color: '#7E8299',
    },
    profileStat: {
        marginBottom: 20,
    },
    statContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
        padding: '0 20px',
    },
    statGroupInfo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            fontSize: 42,
            color: '#7E8299',
        },
        '& p': {
            fontSize: 16,
            color: '#3F4254',
            fontWeight: 500,
            marginLeft: 5,
        },
    },
    profileContainer: {
        // maxWidth: 550,
    },
    statValue: {
        backgroundColor: '#faebd7',
        fontSize: 16,
        color: '#3F4254',
        fontWeight: 500,
        marginLeft: 5,
        padding: '5px 10px',
        borderRadius: 5,
    },
    balanceContainer: {
        // maxWidth: 450,
    },
    bitcoinBackground: {
        backgroundColor: '#1cc5bd',
        borderRadius: 10,
        boxShadow: '0px 0px 30px 0px rgba(82, 63, 105, 0.05)',
    },
    ethBackground: {
        backgroundColor: '#8da2f3',
        borderRadius: 10,
        boxShadow: '0px 0px 30px 0px rgba(82, 63, 105, 0.05)',
    },
    balanceContent: {
        padding: '25px 30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h5': {
            fontSize: 29,
            color: '#FFF',
            fontWeight: 600,
            marginLeft: 10,
        },
    },
    balanceIcon: {
        width: 100,
        height: 100,
        backgroundColor: '#6f88e8',
    },
    planPurchasedContainer: {
        marginTop: 20,
    },
    userInformationTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '30px 0',
    },
    backButton: {},
    titleSection: {
        marginBottom: 20,
    },
});

export const UserShow = () => {
    const classes = useStyles();
    const {id} = useParams();
    const {userShow, userPlan, userWithdraw, userBalances} = useSelector(
        (state: StoreState) => state,
    );

    const [planColumns] = useState([
        {
            title: 'Name',
            field: 'id',
            render: (rowData: IUserPlanData) => {
                return rowData.plan.name;
            },
        },
        {
            title: 'Start date',
            field: 'createAt',
            render: (rowData: IUserPlanData) => {
                return moment(rowData.createAt).format('LLLL');
            },
        },
        {
            title: 'End date',
            field: 'endDate',
            render: (rowData: IUserPlanData) => {
                return moment(rowData.endDate).format('LLLL');
            },
        },
        {
            title: 'Power Unit',
            field: 'power',
            render: (rowData: IUserPlanData) => {
                return `${rowData.power} ${rowData.plan.coin.powerUnit}`;
            },
        },
    ]);

    const [withdrawColumns] = useState([
        {
            title: 'Address',
            field: 'walletAddress',
        },
        {
            title: 'Amount',
            field: 'amount',
            render: (rowData: IWithdrawData) => {
                return `${rowData.amount} ${rowData.coin.code}`;
            },
        },
        {
            title: 'Fee',
            field: 'withdrawFee',
            render: (rowData: IWithdrawData) => {
                return `${rowData.withdrawFee} ${rowData.coin.code}`;
            },
        },
        {
            title: 'Status',
            field: 'status',
            render: (rowData: IWithdrawData) => {
                return WITHDRAW_HISTORY_STATUS_NAMES[rowData.status];
            },
        },
        {
            title: 'Created At',
            field: 'createAt',
            render: (rowData: IWithdrawData) => {
                return moment(rowData.createAt).format('LLLL');
            },
        },
    ]);

    useEffect(() => {
        getUserShow(id);
        getUserBalances(id);
    }, [id]);

    const getPlans = (params: IPaginationRequest) => {
        getUserPlans(id, params);
    };
    const getWithdraws = (params: IPaginationRequest) => {
        getWithdrawHistoryOfUser(id, params);
    };

    return (
        <PageLayout>
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item sm={12} className={classes.userInformationTitle}>
                    <Typography variant="h4">
                        <Tooltip title="Go Back">
                            <IconButton
                                className={classes.backButton}
                                onClick={() => window.history.back()}
                            >
                                <ArrowBackIosIcon />
                            </IconButton>
                        </Tooltip>
                        User Information
                    </Typography>
                </Grid>
                <Grid item md={4} className={classes.profileContainer}>
                    <Box className={classes.contentWrapper}>
                        <Box className={classes.panel}>
                            <Box className={classes.profileTop}>
                                <Avatar
                                    className={classes.avatarContainer}
                                    alt="User Default"
                                    src="https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/svg/avatars/007-boy-2.svg"
                                />
                                <Typography
                                    className={classes.profileUserName}
                                    variant="h5"
                                >
                                    {userShow?.firstName ?? 'N/A'}{' '}
                                    {userShow?.lastName ?? 'N/A'}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    className={classes.profileEmail}
                                >
                                    {userShow?.email}
                                </Typography>
                            </Box>
                            <Box className={classes.profileStat}>
                                <Box className={classes.statContainer}>
                                    <Box className={classes.statGroupInfo}>
                                        <DateRangeIcon />
                                        <Typography>Date of Birth</Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            className={classes.statValue}
                                        >
                                            {userShow?.day}-{userShow?.month}-
                                            {userShow?.year}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={classes.profileStat}>
                                <Box className={classes.statContainer}>
                                    <Box className={classes.statGroupInfo}>
                                        <PhoneAndroidIcon />
                                        <Typography>Phone Number</Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            className={classes.statValue}
                                        >
                                            {userShow?.phoneNumber ?? 'N/A'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={classes.profileStat}>
                                <Box className={classes.statContainer}>
                                    <Box className={classes.statGroupInfo}>
                                        <ClosedCaptionIcon />
                                        <Typography>Referal Code</Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            className={classes.statValue}
                                        >
                                            {userShow?.referralCode ?? 'N/A'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={classes.profileStat}>
                                <Box className={classes.statContainer}>
                                    <Box className={classes.statGroupInfo}>
                                        <WhatshotIcon />
                                        <Typography>Rank</Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            className={classes.statValue}
                                        >
                                            {userShow?.rank?.name ?? 'N/A'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={8}>
                    <Grid container spacing={3}>
                        {!isEmpty(userBalances) ? (
                            userBalances.map((userBalance, index) => (
                                <Grid
                                    item
                                    md={6}
                                    sm={12}
                                    key={index}
                                    className={classes.balanceContainer}
                                >
                                    <Box className={classes.ethBackground}>
                                        <Box className={classes.balanceContent}>
                                            <Avatar
                                                alt={userBalance.coin.name}
                                                className={classes.balanceIcon}
                                            >
                                                {userBalance.coin.code}
                                            </Avatar>
                                            <Typography variant="h5">
                                                {userBalance.amount.toFixed(
                                                    userBalance.coin.decimals,
                                                )}{' '}
                                                {userBalance.coin.code}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))
                        ) : (
                            <Grid item sm={12}>
                                <Grid container spacing={3}>
                                    <Grid
                                        item
                                        md={6}
                                        sm={12}
                                        className={classes.balanceContainer}
                                    >
                                        <Box className={classes.ethBackground}>
                                            <Box
                                                className={
                                                    classes.balanceContent
                                                }
                                            >
                                                <Avatar
                                                    alt={'Bitcoin'}
                                                    className={
                                                        classes.balanceIcon
                                                    }
                                                >
                                                    BTC
                                                </Avatar>
                                                <Typography variant="h5">
                                                    0.000000 BTC
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        sm={12}
                                        className={classes.balanceContainer}
                                    >
                                        <Box className={classes.ethBackground}>
                                            <Box
                                                className={
                                                    classes.balanceContent
                                                }
                                            >
                                                <Avatar
                                                    alt={'Ethererum'}
                                                    className={
                                                        classes.balanceIcon
                                                    }
                                                >
                                                    ETH
                                                </Avatar>
                                                <Typography variant="h5">
                                                    0.000000 ETH
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Grid item sm={12}>
                            <Typography
                                variant="h4"
                                className={classes.titleSection}
                            >
                                Withdraw History
                            </Typography>

                            <Table
                                headers={withdrawColumns}
                                data={userWithdraw.data}
                                loadAction={getWithdraws}
                                page={userWithdraw.pageInfo.currentPage}
                                limit={userWithdraw.pageInfo.pageSize}
                                count={userWithdraw.pageInfo.totalCount}
                                isLoading={userWithdraw.isLoading}
                                searchText={userWithdraw.pageInfo.searchText}
                                title={<span />}
                                tableHeight={328}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} className={classes.planPurchasedContainer}>
                    <Typography variant="h4" className={classes.titleSection}>
                        Plan Purchased
                    </Typography>
                    <Table
                        headers={planColumns}
                        data={userPlan.data}
                        loadAction={getPlans}
                        page={userPlan.pageInfo.currentPage}
                        limit={userPlan.pageInfo.pageSize}
                        count={userPlan.pageInfo.totalCount}
                        isLoading={userPlan.isLoading}
                        searchText={userPlan.pageInfo.searchText}
                        title={<span />}
                    />
                </Grid>
            </Grid>
        </PageLayout>
    );
};
