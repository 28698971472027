import React from 'react';
import {PropTypes} from '@material-ui/core';

interface IProps {
    src: any;
    height?: number;
    width?: number;
    color?: PropTypes.Color;
    onHover?: boolean;
    onActive?: boolean;
    variant?: 'filled' | 'outlined';
    disabled?: boolean;
}

export class SVGImage extends React.Component<IProps> {
    private defaultColor: string | null = null;

    private ref: any = null;
    public getRef = (ref: any) => {
        this.ref = ref;
    };

    public componentDidMount = () => {
        let defaultColor: string | null = 'white';

        if (this.ref && this.ref.firstElementChild) {
            defaultColor = this.ref.firstElementChild.getAttribute('stroke');
            if (defaultColor === null) {
                defaultColor = this.ref.firstElementChild.getAttribute('fill');
            }
        }
        this.defaultColor = defaultColor;
    };

    public setColor = () => {
        if (this.ref && this.ref.childNodes) {
            this.ref.childNodes.forEach((el: any) => {
                if (this.props.variant === 'filled') {
                    el.setAttribute('fill', this.getColor());
                } else {
                    el.setAttribute('stroke', this.getColor());
                }
            });
        }
    };

    public getColor = (): string | null => {
        if (this.props.disabled && this.props.color === 'secondary') {
            return '#F2F5F8';
        }

        if (this.props.onActive) {
            return this.props.color === 'secondary'
                ? 'white'
                : this.props.color === 'inherit'
                ? '#3791D3'
                : this.defaultColor;
        }
        if (this.props.onHover) {
            return this.props.color === 'secondary'
                ? 'white'
                : this.props.color === 'inherit'
                ? '#42A9F5'
                : this.defaultColor;
        }
        return this.defaultColor;
    };

    public render() {
        const Image = this.props.src;
        if (this.props.color !== 'primary') {
            this.setColor();
        }
        return (
            <Image
                ref={this.getRef}
                height={this.props.height}
                width={this.props.width}
            />
        );
    }
}
