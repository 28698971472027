import {createBrowserHistory} from 'history';
export const history = createBrowserHistory();

export const pushTo = (path: string, params?: {}) => {
    let pathWithParams = path;
    if (params) {
        for (const [key, value] of Object.entries(params)) {
            pathWithParams = pathWithParams.replace(':' + key, value as string);
        }
    }
    /// remove all ? on url
    pathWithParams = pathWithParams.replace(/[?]/g, '');
    ///
    history.push(pathWithParams);
};

export const goBack = () => history.goBack();

export const linkPath = (path: string, key: string, value: string) => {
    path = path.replace(`:${key}`, value);
    return path;
};
