import React, {useState, useRef, useEffect} from 'react';
import {PageLayout} from '../../components/PageLayout';
import {makeStyles, Box, Typography, Button} from '@material-ui/core';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import PlanForm from './FlanForm';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {IPlanData, IPlanRequest} from '../../types/plan';
import {
    getPlans,
    addPlan,
    editPlan,
    deactivePlan,
    activePlan,
} from '../../middlewares/plan';
import {getDiscounts} from '../../middlewares/discount';
import {getMiningCoins} from '../../middlewares/coin';

import {PATH} from '../../constants/path';
import EditIcon from '@material-ui/icons/Create';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles({
    contentWrapper: {
        width: '100%',
        '& .MuiButton-startIcon': {
            marginRight: -10,
        },
    },
    titleContainer: {
        width: 400,
        display: 'flex',
        '& h5': {
            marginRight: 20,
        },
    },
});

export const Plans = () => {
    const {plan, coins, discounts} = useSelector((state: StoreState) => state);
    const planFormRef = useRef<any>();
    const classes = useStyles();
    const [columns] = useState([
        {title: 'Name', field: 'name'},
        {title: 'Type', field: 'type'},
        {title: 'Period', field: 'period'},
        {
            title: 'Maintenance Fee',
            field: 'maintenanceFee',
            render: (rowData: IPlanData) => {
                return `${parseFloat(
                    (rowData.maintenanceFee * 100).toFixed(5),
                )} %`;
            },
        },
        {
            title: 'Reward Rate',
            field: 'rewardRate',
            render: (rowData: IPlanData) => {
                return `${parseFloat(
                    ((rowData.rewardRate - 1) * 100).toFixed(5),
                )} %`;
            },
        },
        {
            title: 'Is Active',
            field: 'actFlag',
            render: (rowData: IPlanData) => {
                if (rowData.actFlag) {
                    return 'Yes';
                }
                return 'No';
            },
        },
    ]);

    const [modalTitle, setModalTitle] = useState<string>('Add Plan');
    const [planSelected, setPlanSelected] = useState<IPlanData | null>(null);

    const [open, setOpen] = useState<boolean>(false);

    const handleDeactivePlan = (_event: any, rowData: IPlanData) => {
        if (rowData.actFlag) {
            deactivePlan(rowData.id);
            return;
        }
        activePlan(rowData.id);
    };

    const handleSubmit = () => {
        const isFormValid = planFormRef.current.validate();
        if (!isFormValid) {
            return; /// do nothing
        }
        const formData: IPlanRequest = planFormRef.current.getFormData();
        // check type of request
        if (planSelected) {
            editPlan(formData, planSelected.id);
        } else {
            addPlan(formData);
        }
        setOpen(false);
    };

    const handleAddPlan = (_event: any) => {
        setModalTitle('Add plan');
        setPlanSelected(null);
        setOpen(true);
    };

    const handleEditPlan = (_event: any, rowData: IPlanData) => {
        setModalTitle('Edit Plan');
        setPlanSelected(rowData);
        setOpen(true);
    };

    useEffect(() => {
        getDiscounts();
        getMiningCoins();
    }, []);

    return (
        <PageLayout>
            <Box className={classes.contentWrapper}>
                <Table
                    headers={columns}
                    data={plan.data}
                    loadAction={getPlans}
                    page={plan.pageInfo.currentPage}
                    limit={plan.pageInfo.pageSize}
                    count={plan.pageInfo.totalCount}
                    isLoading={plan.isLoading}
                    path={PATH.plans}
                    searchText={plan.pageInfo.searchText}
                    title={
                        <Box className={classes.titleContainer}>
                            <Typography variant="h5">Plan</Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={handleAddPlan}
                            >
                                Add Plan
                            </Button>
                        </Box>
                    }
                    actions={[
                        {
                            icon: () => <EditIcon />,
                            tooltip: 'Edit Plan',
                            onClick: handleEditPlan,
                        },
                        {
                            icon: (rowData: IPlanData) => {
                                if (rowData.actFlag) {
                                    return <RemoveCircleIcon />;
                                }
                                return <CheckCircleIcon />;
                            },
                            tooltip: (rowData: IPlanData) => {
                                if (rowData.actFlag) {
                                    return 'Deactive Plan';
                                }
                                return 'Active Plan';
                            },
                            onClick: handleDeactivePlan,
                        },
                    ]}
                />
            </Box>
            <Box>
                <Modal
                    maxWidth={'md'}
                    open={open}
                    handleClose={() => setOpen(false)}
                    handleSubmit={handleSubmit}
                    hasClose
                    title={modalTitle}
                >
                    <PlanForm
                        plan={planSelected}
                        coins={coins}
                        discounts={discounts}
                        ref={planFormRef}
                    />
                </Modal>
            </Box>
        </PageLayout>
    );
};
