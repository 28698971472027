import {
    SET_COIN,
    ISetCoinAction,
    SET_OVERVIEW_COIN,
    ISetOverviewCoinAction,
    IGetCoinListRequestAction,
    ISetCoinListResponseAction,
    GET_COIN_LIST_REQUEST,
    SET_COIN_LIST_RESPONSE,
} from '../actions/coin';
import {ICoin, IOverviewCoin, ICoinList} from '../types/coin';
import {DEFAULT_PAGE_SIZE} from '../constants/pagination';

export const coins = (state: ICoin[] = [], action: ISetCoinAction) => {
    if (action.type === SET_COIN) {
        return action.payload;
    }
    /// default state
    return state;
};

const initState: ICoinList = {
    data: [],
    pageInfo: {
        totalCount: 0,
        currentPage: 1,
        totalPages: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        searchText: '',
    },
    isLoading: false,
};

export const coinList = (
    state: ICoinList = initState,
    action: IGetCoinListRequestAction | ISetCoinListResponseAction,
) => {
    if (action.type === GET_COIN_LIST_REQUEST) {
        return {
            ...state,
            isLoading: true,
        };
    }

    if (action.type === SET_COIN_LIST_RESPONSE) {
        return {
            ...state,
            data: action.payload.data,
            pageInfo: action.payload.pageInfo,
            isLoading: false,
        };
    }
    return state;
};

///////////////////////////////////////////
export const overviewCoins = (
    state: IOverviewCoin[] = [],
    action: ISetOverviewCoinAction,
) => {
    if (action.type === SET_OVERVIEW_COIN) {
        return action.payload;
    }
    /// default state
    return state;
};
