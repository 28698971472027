export const WITHDRAW_HISTORY_STATUS_NAMES = {
    1: 'Created',
    2: 'Confirming',
    3: 'Sent',
    4: 'Expired',
    5: 'Ended',
    10: 'Ready',
    11: 'Using',
    20: 'Approved',
    21: 'Rejected',
};

export const WITHDRAW_CREATED_STATUS = 1;
