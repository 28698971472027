import {store} from '..';
import Axios, {AxiosResponse, AxiosError} from 'axios';
import {IDiscountList, IDiscountRequest, IDiscount} from '../types/discount';
import {IPaginationRequest} from '../types/pagination';
import {
    getDiscountListRequest,
    setDiscountList,
    setDiscounts,
} from '../actions/discount';
import {
    fireErrorNotification,
    fireSuccessNotification,
} from '../helpers/notification';

export const getDiscounts = () => {
    Axios.get('/Discount/GetActiveDiscount')
        .then((response: AxiosResponse<IDiscount[]>) => {
            store.dispatch(setDiscounts(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const getDiscountsByPagination = (params: IPaginationRequest) => {
    const {pageNumber, pageSize} = params;
    store.dispatch(getDiscountListRequest());
    Axios.get(
        `/Discount/GetDiscount?PageNumber=${pageNumber}&PageSize=${pageSize}`,
    )
        .then((response: AxiosResponse<IDiscountList>) => {
            store.dispatch(setDiscountList(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const addDiscount = (params: IDiscountRequest) => {
    Axios.post('/Discount', params)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const editDiscount = (params: IDiscountRequest, id: string) => {
    Axios.put(`/Discount/${id}`, params)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const deactiveDiscount = (id: string) => {
    Axios.put(`/Discount/Deactive/${id}`)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
export const activeDiscount = (id: string) => {
    Axios.put(`/Discount/Active/${id}`)
        .then((_response: AxiosResponse) => {
            fireSuccessNotification('Success!');
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
