import React, {useState, useRef} from 'react';
import {PageLayout} from '../../components/PageLayout';
import {makeStyles, Box, Typography, Button} from '@material-ui/core';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import RankForm from './RankForm';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {IRankData, IRankRequest} from '../../types/rank';
import {
    getRanks,
    deactiveRank,
    activeRank,
    editRank,
    addRank,
} from '../../middlewares/rank';
import {PATH} from '../../constants/path';
import EditIcon from '@material-ui/icons/Create';
import {maxBy} from 'lodash';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles({
    contentWrapper: {
        width: '100%',
        '& .MuiButton-startIcon': {
            marginRight: -10,
        },
    },
    titleContainer: {
        width: 400,
        display: 'flex',
        '& h5': {
            marginRight: 20,
        },
    },
});

export const Ranks = () => {
    const {rank} = useSelector((state: StoreState) => state);
    const rankFormRef = useRef<any>();
    const classes = useStyles();
    const [columns] = useState([
        {title: 'Name', field: 'name'},
        {title: 'Level', field: 'level'},
        {
            title: 'Discount Percent',
            field: 'percent',
            render: (rowData: IRankData) => {
                return `${(rowData.percent * 100).toFixed(2)} %`;
            },
        },
        {
            title: 'Next Level Require',
            field: 'nextLevelRequired',
            render: (rowData: IRankData) => {
                return `${rowData.nextLevelRequired} USD`;
            },
        },
        {
            title: 'Is Active',
            field: 'actFlag',
            render: (rowData: IRankData) => {
                if (rowData.actFlag) {
                    return 'Yes';
                }
                return 'No';
            },
        },
    ]);

    const [modalTitle, setModalTitle] = useState<string>('Add Rank');
    const [rankSelected, setRankSelected] = useState<IRankData | null>(null);
    const [nextLevel, setNextLevel] = useState<number>(0);

    const [open, setOpen] = useState<boolean>(false);

    const handleSubmit = () => {
        const isFormValid = rankFormRef.current.validate();
        if (!isFormValid) {
            return; /// do nothing
        }
        const formData: IRankRequest = rankFormRef.current.getFormData();
        console.log('formData', formData);
        // check type of request
        if (rankSelected) {
            editRank(formData, rankSelected.id);
        } else {
            addRank(formData);
        }
        setOpen(false);
    };

    const handleAddRank = (_event: any) => {
        setModalTitle('Add rank');
        const latestRank = maxBy(rank.data, (rankData) => rankData.level);
        let maxLevel = 0;
        if (latestRank) {
            maxLevel = latestRank.level + 1;
        }
        setNextLevel(maxLevel);
        setRankSelected(null);
        setOpen(true);
    };

    const handleEditRank = (_event: any, rowData: IRankData) => {
        setModalTitle('Edit Rank');
        setNextLevel(0);
        setRankSelected(rowData);
        setOpen(true);
    };

    const handleDeactivePlan = (_event: any, rowData: IRankData) => {
        if (rowData.actFlag) {
            deactiveRank(rowData.id);
            return;
        }
        activeRank(rowData.id);
    };

    return (
        <PageLayout>
            <Box className={classes.contentWrapper}>
                <Table
                    headers={columns}
                    data={rank.data}
                    loadAction={getRanks}
                    page={rank.pageInfo.currentPage}
                    limit={rank.pageInfo.pageSize}
                    count={rank.pageInfo.totalCount}
                    isLoading={rank.isLoading}
                    path={PATH.ranks}
                    searchText={rank.pageInfo.searchText}
                    title={
                        <Box className={classes.titleContainer}>
                            <Typography variant="h5">Rank</Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={handleAddRank}
                            >
                                Add Rank
                            </Button>
                        </Box>
                    }
                    actions={[
                        {
                            icon: () => <EditIcon />,
                            tooltip: 'Edit Rank',
                            onClick: handleEditRank,
                        },
                        {
                            icon: (rowData: IRankData) => {
                                if (rowData.actFlag) {
                                    return <RemoveCircleIcon />;
                                }
                                return <CheckCircleIcon />;
                            },
                            tooltip: (rowData: IRankData) => {
                                if (rowData.actFlag) {
                                    return 'Deactive Rank';
                                }
                                return 'Active Rank';
                            },
                            onClick: handleDeactivePlan,
                        },
                    ]}
                />
            </Box>
            <Box>
                <Modal
                    maxWidth={'md'}
                    open={open}
                    handleClose={() => setOpen(false)}
                    handleSubmit={handleSubmit}
                    hasClose
                    title={modalTitle}
                >
                    <RankForm
                        rank={rankSelected}
                        ref={rankFormRef}
                        nextLevel={nextLevel}
                    />
                </Modal>
            </Box>
        </PageLayout>
    );
};
