import React, {useState, useRef} from 'react';
import {PageLayout} from '../../components/PageLayout';
import {makeStyles, Box, Typography, Button} from '@material-ui/core';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import {useSelector} from 'react-redux';
import DiscountForm from './DiscountForm';
import {StoreState} from '../../types/store-state';
import {IDiscount, IDiscountRequest} from '../../types/discount';
import {
    getDiscountsByPagination,
    editDiscount,
    addDiscount,
    deactiveDiscount,
    activeDiscount,
} from '../../middlewares/discount';
import {PATH} from '../../constants/path';
import EditIcon from '@material-ui/icons/Create';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import moment from 'moment';

const useStyles = makeStyles({
    contentWrapper: {
        width: '100%',
        '& .MuiButton-startIcon': {
            marginRight: -10,
        },
    },
    titleContainer: {
        width: 400,
        display: 'flex',
        '& h5': {
            marginRight: 20,
        },
    },
});

export const Discounts = () => {
    const {discountList} = useSelector((state: StoreState) => state);
    const discountFormRef = useRef<any>();
    const classes = useStyles();
    const [columns] = useState([
        {title: 'Name', field: 'name'},
        {
            title: 'Percent',
            field: 'percent',
            render: (rowData: IDiscount) => {
                return `${parseFloat((rowData.percent * 100).toFixed(5))} %`;
            },
        },
        {title: 'Limit', field: 'limit'},
        {
            title: 'Valid Until',
            field: 'validUntil',
            render: (rowData: IDiscount) => {
                return moment(rowData.validUntil)
                    .utc()
                    .format('MMMM Do YYYY, h:mm a');
            },
        },
        {
            title: 'Is Active',
            field: 'actFlag',
            render: (rowData: IDiscount) => {
                if (rowData.actFlag) {
                    return 'Yes';
                }
                return 'No';
            },
        },
    ]);

    const [modalTitle, setModalTitle] = useState<string>('Add Discount');
    const [discountSelected, setDiscountSelected] = useState<IDiscount | null>(
        null,
    );

    const [open, setOpen] = useState<boolean>(false);

    const handleSubmit = () => {
        const isFormValid = discountFormRef.current.validate();
        if (!isFormValid) {
            return; /// do nothing
        }
        const formData: IDiscountRequest = discountFormRef.current.getFormData();
        // check type of request
        if (discountSelected) {
            editDiscount(formData, discountSelected.id);
        } else {
            addDiscount(formData);
        }
        setOpen(false);
    };

    const handleAddDiscount = (_event: any) => {
        setModalTitle('Add Discount');
        setDiscountSelected(null);
        setOpen(true);
    };

    const handleEditDiscount = (_event: any, rowData: IDiscount) => {
        setModalTitle('Edit Discount');
        setDiscountSelected(rowData);
        setOpen(true);
    };

    const handleDeactiveDiscount = (_event: any, rowData: IDiscount) => {
        if (rowData.actFlag) {
            deactiveDiscount(rowData.id);
            return;
        }
        activeDiscount(rowData.id);
    };

    return (
        <PageLayout>
            <Box className={classes.contentWrapper}>
                <Table
                    headers={columns}
                    data={discountList.data}
                    loadAction={getDiscountsByPagination}
                    page={discountList.pageInfo.currentPage}
                    limit={discountList.pageInfo.pageSize}
                    count={discountList.pageInfo.totalCount}
                    isLoading={discountList.isLoading}
                    path={PATH.plans}
                    searchText={discountList.pageInfo.searchText}
                    title={
                        <Box className={classes.titleContainer}>
                            <Typography variant="h5">Discount</Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={handleAddDiscount}
                            >
                                Add Discount
                            </Button>
                        </Box>
                    }
                    actions={[
                        {
                            icon: () => <EditIcon />,
                            tooltip: 'Edit Discount',
                            onClick: handleEditDiscount,
                        },
                        {
                            icon: (rowData: IDiscount) => {
                                if (rowData.actFlag) {
                                    return <RemoveCircleIcon />;
                                }
                                return <CheckCircleIcon />;
                            },
                            tooltip: (rowData: IDiscount) => {
                                if (rowData.actFlag) {
                                    return 'Deactive Discount';
                                }
                                return 'Active Discount';
                            },
                            onClick: handleDeactiveDiscount,
                        },
                    ]}
                />
            </Box>
            <Box>
                <Modal
                    maxWidth={'md'}
                    open={open}
                    handleClose={() => setOpen(false)}
                    handleSubmit={handleSubmit}
                    hasClose
                    title={modalTitle}
                >
                    <DiscountForm
                        discount={discountSelected}
                        ref={discountFormRef}
                    />
                </Modal>
            </Box>
        </PageLayout>
    );
};
