import React, {useState} from 'react';
import {Grid, Typography, Button, makeStyles} from '@material-ui/core';
import {isValidEmail} from '../../helpers/validate';
import {forgotPasswordMiddleware} from '../../middlewares/auth';
import {find} from 'lodash';
import {ReCaptcha} from '../../components/ReCAPTCHA';
import {Input} from '../../components/Input';

const styles = makeStyles({
    autofill: {
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            borderRadius: 3,
            padding: 12,
        },
    },
});

export const ForgotPassword = ({history}) => {
    const classes = styles();
    const [state, setState] = useState({
        email: {value: '', error: ''},
        reCaptcha: {value: '', error: ''},
    });

    const handleSubmit = () => {
        forgotPasswordMiddleware({
            email: state.email.value.trim().toLocaleLowerCase(),
            googleReCaptchaResponse: state.reCaptcha.value,
        });
    };

    const onChangeText = (event) => {
        let error = '';
        if (isValidEmail(event.target.value) === false) {
            error = 'Email is invalid';
        }
        setState({
            ...state,
            email: {
                error,
                value: event.target.value,
            },
        });
    };

    const onChangeReCaptcha = (token: string | null) =>
        setState({
            ...state,
            reCaptcha: {
                value: token || '',
                error: '',
            },
        });

    const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && disableSubmit === false) {
            event.preventDefault();
            handleSubmit();
        }
    };

    const disableSubmit = Boolean(
        find(state, (el) => el.value === '' || el.error !== ''),
    );

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
                height: `calc(100vh)`,
                position: 'relative',
                backgroundColor: 'lightGrey',
            }}
            className={classes.autofill}
        >
            <img
                src={''}
                alt="Logo"
                style={{position: 'absolute', top: 40, left: 40}}
            />
            <Typography variant="h1" style={{color: 'white'}}>
                Forgot password
            </Typography>

            <Typography variant="subtitle1">
                Recover your password via e-mail
            </Typography>

            <Grid
                container
                direction="column"
                justify="space-between"
                style={{
                    backgroundColor: 'white',
                    height: 330,
                    width: 444,
                    marginTop: 28,
                    marginBottom: 20,
                    padding: '40px 30px',
                    boxShadow: '0px 0px 100px #162CA7',
                    borderRadius: 3,
                }}
            >
                <Input
                    label="Email"
                    type="email"
                    value={state.email.value}
                    helperText={state.email.error}
                    error={state.email.error ? true : false}
                    onChange={onChangeText}
                    onKeyPress={onKeyPress}
                />

                <ReCaptcha
                    onChange={onChangeReCaptcha}
                    containerStyle={{marginTop: 20}}
                />

                <Grid container justify="center" style={{marginTop: 30}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={disableSubmit}
                    >
                        Send
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
