import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import {
    makeStyles,
    TextField,
    Typography,
    IconButton,
    InputAdornment,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Grid,
    FormHelperText,
    Box,
} from '@material-ui/core';

import {Add as AddIcon, Close as CloseIcon} from '@material-ui/icons';
import {IPlanData, IPlanRequest} from '../../types/plan';
import {ICoin} from '../../types/coin';
import {IDiscount} from '../../types/discount';
import {PLAN_TYPES, RADIANT_CLASSIC} from '../../constants/plan';
import {map, has, isEmpty} from 'lodash';
import {camelToTitleCase} from '../../helpers/common';
import {useDropzone} from 'react-dropzone';

///////////////////
const formStyles = makeStyles({
    formGroup: {
        marginBottom: 10,
        width: '100%',
    },
    title: {},
    iconAdd: {
        backgroundColor: '#3f51b5',
        color: '#FFF',
        marginLeft: 10,
        '&:hover': {
            backgroundColor: '#324092',
        },
    },
    titleAddContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    bagde: {
        fontSize: 13,
        backgroundColor: '#f0b90a',
        marginRight: 15,
        padding: '2px 5px',
        borderRadius: 5,
        color: '#FFF',
    },
    imageContainer: {
        width: 200,
        height: 200,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: 5,
        cursor: 'pointer',
        border: '2px solid #ffab32',
        borderRadius: 5,
    },
    imageContent: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
});

interface IProps {
    plan: IPlanData | null;
    coins: ICoin[];
    discounts: IDiscount[];
    ref: any;
}

export interface IPlanFormState {
    name: {value: string; error: string};
    coinId: {value: string; error: string};
    period: {value: number; error: string};
    type: {value: string; error: string};
    automaticallyUpdatePrice: {value: number; error: string};
    rewardRate: {value: number; error: string};
    maintenanceFee: {value: number; error: string};
    planDetails: IPlanFormDetail[];
    image: {value: string | undefined; error: string};
    discountId: {value: string; error: string};
}

export interface IPlanFormDetail {
    power: {value: number; error: string};
    price: {value: number; error: string};
}
const DEFAULT_DISCOUNT = {
    value: 'no',
    label: 'No',
};

const PlanForm = forwardRef((props: IProps, ref: any) => {
    const {plan, coins, discounts} = props;
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        multiple: false,
    });
    const [state, setState] = useState<IPlanFormState>({
        name: {value: '', error: ''},
        coinId: {value: '', error: ''},
        period: {value: 30, error: ''},
        automaticallyUpdatePrice: {value: 0, error: ''},
        rewardRate: {value: 0, error: ''},
        type: {value: RADIANT_CLASSIC, error: ''},
        maintenanceFee: {value: 0, error: ''},
        planDetails: [
            {
                power: {value: 0, error: ''},
                price: {value: 0, error: ''},
            },
            {
                power: {value: 0, error: ''},
                price: {value: 0, error: ''},
            },
        ],
        image: {value: '', error: ''},
        discountId: {value: DEFAULT_DISCOUNT.value, error: ''},
    });
    const [base64Image, setBase64Image] = useState('');

    useImperativeHandle(ref, () => ({
        getFormData: getFormData,
        validate: validateData,
    }));

    const getFormData = () => {
        const formData: IPlanRequest = {
            name: state.name.value,
            coinId: state.coinId.value,
            period: state.period.value,
            type: state.type.value,
            automaticallyUpdatePrice: state.automaticallyUpdatePrice.value
                ? true
                : false,
            rewardRate:
                parseFloat((state.rewardRate.value / 100).toFixed(5)) + 1,
            maintenanceFee: parseFloat(
                (state.maintenanceFee.value / 100).toFixed(5),
            ),
            planDetails: state.planDetails.map((planDetail) => {
                return {
                    power: planDetail.power.value,
                    price: planDetail.price.value,
                };
            }),
        };
        if (!plan || plan.image !== state.image.value) {
            formData.base64Image = base64Image.split(',')[1];
        }
        if (state.discountId.value !== DEFAULT_DISCOUNT.value) {
            formData.discountId = state.discountId.value;
        }
        return formData;
    };

    useEffect(() => {
        if (plan) {
            const newState = {
                name: {value: plan.name, error: ''},
                coinId: {value: plan.coinId, error: ''},
                period: {value: plan.period, error: ''},
                type: {value: plan.type, error: ''},
                automaticallyUpdatePrice: {
                    value: plan.automaticallyUpdatePrice ? 1 : 0,
                    error: '',
                },
                rewardRate: {
                    value: parseFloat(((plan.rewardRate - 1) * 100).toFixed(5)),
                    error: '',
                },
                maintenanceFee: {
                    value: parseFloat((plan.maintenanceFee * 100).toFixed(5)),
                    error: '',
                },
                planDetails: plan.planDetails.map((planDetail) => {
                    return {
                        power: {value: planDetail.power, error: ''},
                        price: {value: planDetail.price, error: ''},
                    };
                }),
                image: {value: plan.image, error: ''},
                discountId: {
                    value: plan.discountId ?? DEFAULT_DISCOUNT.value,
                    error: '',
                },
            };
            setState(newState);
        }
    }, [plan]);

    useEffect(() => {
        if (!isEmpty(acceptedFiles)) {
            const chosenFile: File = acceptedFiles[0];
            const reader = new FileReader();
            reader.onload = () => {
                const image = reader.result as string;
                setState({
                    ...state,
                    image: {
                        value: image,
                        error: '',
                    },
                });
                setBase64Image(image);
            };
            reader.readAsDataURL(chosenFile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles]);

    const validateData = () => {
        let newState = {...state};
        let isValid = true;
        map(newState, (attribute: any, key: string) => {
            if (has(attribute, 'value')) {
                if (attribute.value === '') {
                    isValid = false;
                    attribute.error = `${camelToTitleCase(
                        key,
                    )} can not be empty`;
                }
                return attribute;
            }
            return attribute.map((child: any, childKey: string) => {
                if (has(child, 'value')) {
                    if (child.value === '') {
                        isValid = false;
                        child.error = `${camelToTitleCase(
                            childKey,
                        )} can not be empty`;
                    }
                }
                return child;
            });
        });
        setState(newState);
        return isValid;
    };

    const onChangeState = (key: string, event: React.ChangeEvent<any>) => {
        event.persist();
        let value = event.target.value;
        // check if value is an number type
        if (!isNaN(parseFloat(value))) {
            if (value < 0) {
                value = 0;
            }
        }
        setState({
            ...state,
            [key]: {
                value: value,
                error: '',
            },
        });
    };
    const onChangePlanDetail = (
        key: string,
        event: React.ChangeEvent<any>,
        index: number,
    ) => {
        event.persist();
        const value = event.target.value;
        const error = '';
        if (value < 0) {
            return;
        }
        const newPlanDetails = [...state.planDetails];
        newPlanDetails[index][key] = {
            value: value,
            error: error,
        };

        setState({
            ...state,
            planDetails: newPlanDetails,
        });
    };

    const removePlanDetail = (index: number) => {
        const newPlanDetails = [...state.planDetails];
        newPlanDetails.splice(index, 1);
        setState({
            ...state,
            planDetails: newPlanDetails,
        });
    };
    const addPlanDetail = () => {
        const newPlanDetails = [...state.planDetails];
        newPlanDetails.push({
            power: {value: 0, error: ''},
            price: {value: 0, error: ''},
        });
        setState({
            ...state,
            planDetails: newPlanDetails,
        });
    };

    const classes = formStyles();
    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Typography variant="h6" className={classes.title}>
                        Image
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Box
                        {...getRootProps({className: 'dropzone'})}
                        className={classes.imageContainer}
                    >
                        <input {...getInputProps()} />
                        {state.image.value && state.image.value !== '' ? (
                            <img
                                alt="coin"
                                src={state.image.value}
                                className={classes.imageContent}
                            />
                        ) : (
                            <p>
                                Drag and drop file here, or click to select file
                            </p>
                        )}
                    </Box>
                    {state.image.error ? (
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                            {state.image.error}
                        </p>
                    ) : null}
                </Grid>
                <Grid item md={12}>
                    <Typography variant="h6" className={classes.title}>
                        General
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item md={8}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    value={state.name.value}
                                    error={state.name.error !== ''}
                                    helperText={state.name.error}
                                    onChange={(e) => onChangeState('name', e)}
                                    label="Name"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl
                                variant="outlined"
                                className={classes.formGroup}
                            >
                                <InputLabel
                                    shrink
                                    error={state.type.error !== ''}
                                >
                                    Type
                                </InputLabel>
                                <Select
                                    label="Type"
                                    value={state.type.value}
                                    onChange={(e) => onChangeState('type', e)}
                                >
                                    {PLAN_TYPES.map((type, index) => (
                                        <MenuItem key={index} value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    {state.type.error}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item md={4}>
                            <FormControl
                                variant="outlined"
                                className={classes.formGroup}
                            >
                                <InputLabel
                                    shrink
                                    error={state.coinId.error !== ''}
                                >
                                    Coin
                                </InputLabel>
                                <Select
                                    label="coin"
                                    value={state.coinId.value}
                                    onChange={(e) => onChangeState('coinId', e)}
                                >
                                    {coins.map((coin, index) => (
                                        <MenuItem key={index} value={coin.id}>
                                            {coin.code}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    {state.coinId.error}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    label="Period"
                                    type="number"
                                    value={state.period.value}
                                    error={state.period.error !== ''}
                                    helperText={state.period.error}
                                    onChange={(e) => onChangeState('period', e)}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span className={classes.bagde}>
                                                    Days
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    label="Maintenance Fee"
                                    type="number"
                                    value={state.maintenanceFee.value}
                                    error={state.maintenanceFee.error !== ''}
                                    helperText={state.maintenanceFee.error}
                                    onChange={(e) =>
                                        onChangeState('maintenanceFee', e)
                                    }
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span className={classes.bagde}>
                                                    %
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item md={4}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    label="Reward Rate"
                                    type="number"
                                    value={state.rewardRate.value}
                                    error={state.rewardRate.error !== ''}
                                    helperText={state.rewardRate.error}
                                    onChange={(e) =>
                                        onChangeState('rewardRate', e)
                                    }
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span className={classes.bagde}>
                                                    %
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl
                                variant="outlined"
                                className={classes.formGroup}
                            >
                                <InputLabel
                                    shrink
                                    error={
                                        state.automaticallyUpdatePrice.error !==
                                        ''
                                    }
                                >
                                    Is Automatically Update Price
                                </InputLabel>
                                <Select
                                    label="Is Automatically Update Price"
                                    value={state.automaticallyUpdatePrice.value}
                                    onChange={(e) =>
                                        onChangeState(
                                            'automaticallyUpdatePrice',
                                            e,
                                        )
                                    }
                                >
                                    <MenuItem value={0}>No</MenuItem>
                                    <MenuItem value={1}>Yes</MenuItem>
                                </Select>
                                <FormHelperText>
                                    {state.automaticallyUpdatePrice.error}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} className={classes.titleAddContainer}>
                    <Typography variant="h6" className={classes.title}>
                        Price Details
                    </Typography>
                    <IconButton
                        className={classes.iconAdd}
                        onClick={addPlanDetail}
                        color="primary"
                    >
                        <AddIcon />
                    </IconButton>
                </Grid>
                <Grid item md={12}>
                    {state.planDetails.map((planDetail, index) => (
                        <Grid container spacing={3} key={index}>
                            <Grid item md={5}>
                                <FormControl className={classes.formGroup}>
                                    <TextField
                                        label="Power"
                                        type="number"
                                        disabled={index === 0}
                                        value={planDetail.power.value}
                                        error={planDetail.power.error !== ''}
                                        helperText={planDetail.power.error}
                                        onChange={(e) =>
                                            onChangePlanDetail(
                                                'power',
                                                e,
                                                index,
                                            )
                                        }
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={5}>
                                <FormControl className={classes.formGroup}>
                                    <TextField
                                        label="Price"
                                        type="number"
                                        disabled={
                                            index === 0 ||
                                            state.automaticallyUpdatePrice
                                                .value === 1
                                        }
                                        value={planDetail.price.value}
                                        error={planDetail.price.error !== ''}
                                        helperText={planDetail.price.error}
                                        onChange={(e) =>
                                            onChangePlanDetail(
                                                'price',
                                                e,
                                                index,
                                            )
                                        }
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <span
                                                        className={
                                                            classes.bagde
                                                        }
                                                    >
                                                        USD
                                                    </span>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={2}>
                                {state.planDetails.length > 2 && index > 0 ? (
                                    <IconButton
                                        onClick={() => removePlanDetail(index)}
                                        color="secondary"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                ) : null}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Grid item md={12}>
                    <Typography variant="h6" className={classes.title}>
                        Discount
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item md={4}>
                            <FormControl
                                variant="outlined"
                                className={classes.formGroup}
                            >
                                <InputLabel
                                    shrink
                                    error={state.coinId.error !== ''}
                                >
                                    Discount
                                </InputLabel>
                                <Select
                                    label="Discount"
                                    value={state.discountId.value}
                                    onChange={(e) =>
                                        onChangeState('discountId', e)
                                    }
                                >
                                    <MenuItem value={DEFAULT_DISCOUNT.value}>
                                        {DEFAULT_DISCOUNT.label}
                                    </MenuItem>
                                    {discounts.map((discount, index) => (
                                        <MenuItem
                                            key={index}
                                            value={discount.id}
                                        >
                                            {discount.name} -{' '}
                                            {parseFloat(
                                                (
                                                    discount.percent * 100
                                                ).toFixed(5),
                                            )}
                                            %
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    {state.discountId.error}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
});

export default PlanForm;
