export const RADIANT_CLASSIC = 'Radiant Classic';
export const RADIANT_ZERO = 'Radiant Zero';
export const PLAN_TYPES = [RADIANT_CLASSIC, RADIANT_ZERO];

export const DEFAULT_POWER_UNIT = 'MH/s';
export const POWER_UNITS = [
    'H/s',
    'kH/s',
    DEFAULT_POWER_UNIT,
    'GH/s',
    'TH/s',
    'PH/s',
    'EH/s',
];
