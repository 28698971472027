import React from 'react';
import {Grid, Typography, Button} from '@material-ui/core';

export const SignUpSuccess = () => {
    const resendEmail = () => {};

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
                height: `calc(100vh)`,
                position: 'relative',
                backgroundColor: 'lightGrey',
            }}
        >
            <img
                src={''}
                alt="Logo"
                style={{position: 'absolute', top: 40, left: 40}}
            />
            <Typography variant="h1" style={{color: 'white'}}>
                Verify e-mail
            </Typography>
            <Typography variant="subtitle1">
                Please check your e-mail Inbox and click on the verification
                link to activate your registration.
            </Typography>
            <Typography variant="subtitle2">
                Didn’t receive the e-mail? Please check your Junk/Spam folder,
                or click on the button below to resend the verification e-mail.{' '}
            </Typography>

            <Grid container justify="center" style={{marginTop: 30}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={resendEmail}
                >
                    Resend Verification E-mail
                </Button>
            </Grid>
        </Grid>
    );
};

export const ForgotPasswordSuccess = () => {
    const resendEmail = () => {};

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
                height: `calc(100vh)`,
                position: 'relative',
                backgroundColor: 'lightGrey',
            }}
        >
            <img
                src={''}
                alt="Logo"
                style={{position: 'absolute', top: 40, left: 40}}
            />
            <Typography variant="h1" style={{color: 'white'}}>
                Verify e-mail
            </Typography>
            <Typography variant="subtitle1">
                Please check your e-mail Inbox and click on the password reset
                link.
            </Typography>
            <Typography variant="subtitle2">
                Didn’t receive the e-mail? Please check your Junk/Spam folder,
                or click on the button below to resend the verification e-mail.{' '}
            </Typography>

            <Grid container justify="center" style={{marginTop: 30}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={resendEmail}
                >
                    Resend Verification E-mail
                </Button>
            </Grid>
        </Grid>
    );
};
