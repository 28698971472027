import React, {useState, useEffect} from 'react';
import {Grid, Typography, Button} from '@material-ui/core';
import {verifyTwoFactor} from '../../middlewares/auth';
import {Input} from '../../components/Input';
import {pushTo} from '../../helpers/history';
import {PATH} from '../../constants/path';
import {isString} from 'lodash';

export const TwoFactorAuthentication = () => {
    const [twoFactorCode, setTwoFactorCode] = useState({
        value: '',
        error: '',
    });
    const [token, setToken] = useState<string>('');

    useEffect(() => {
        const secretCode = sessionStorage.getItem('secretCode');
        if (!isString(secretCode)) {
            pushTo(PATH.login);
            return;
        }
        setToken(secretCode);
    }, []);

    const handleSubmit = () => {
        verifyTwoFactor({
            token: token,
            twoFactorCode: twoFactorCode.value,
        });
    };

    const onChangeText = (event: React.ChangeEvent<any>) => {
        setTwoFactorCode({
            value: event.target.value,
            error: '',
        });
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && disableSubmit === false) {
            event.preventDefault();
            handleSubmit();
        }
    };

    const disableSubmit = Boolean(
        twoFactorCode.value === '' || twoFactorCode.error !== '',
    );
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
                height: `calc(100vh)`,
                position: 'relative',
                backgroundColor: 'lightGrey',
            }}
        >
            <img
                src={''}
                alt="Logo"
                style={{position: 'absolute', top: 40, left: 40}}
            />
            <Typography variant="h1" style={{color: 'white'}}>
                Login
            </Typography>
            <Typography variant="h6" style={{color: 'white'}}>
                Please enter your login data
            </Typography>

            <Grid
                container
                direction="column"
                justify="space-between"
                style={{
                    backgroundColor: 'white',
                    height: 400,
                    width: 444,
                    marginTop: 28,
                    marginBottom: 20,
                    padding: '40px 30px',
                    boxShadow: '0px 0px 100px #162CA7',
                    borderRadius: 3,
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Input
                    label="Google Auth Code"
                    type="text"
                    style={{height: 100}}
                    value={twoFactorCode.value}
                    helperText={twoFactorCode.error}
                    error={twoFactorCode.error ? true : false}
                    onChange={onChangeText}
                    onKeyPress={onKeyPress}
                />

                <Grid container justify="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={disableSubmit}
                    >
                        Login
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
