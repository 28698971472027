import {SetUserProfileAction, SET_USER_PROFILE} from '../actions/auth';
import {UserProfile, TwoFactor, IWallet} from '../types/setting';
import {
    SET_TWO_FACTOR,
    ISetTwoFactorAction,
    SET_WALLET,
    ISetWalletAction,
} from '../actions/setting';
import {DEFAULT_WALLETS} from '../constants/wallet';
import {isEmpty} from 'lodash';

////////////////
////////////////
export const userProfile = (
    state: UserProfile | null = null,
    action: SetUserProfileAction,
) => {
    if (action.type === SET_USER_PROFILE) {
        return action.payload;
    }
    return state;
};
////////////////
////////////////
export const userTwoFactor = (
    state: TwoFactor | null = null,
    action: ISetTwoFactorAction,
) => {
    if (action.type === SET_TWO_FACTOR) {
        return action.payload;
    }
    return state;
};
////////////////
////////////////
export const userWallets = (
    state: IWallet[] = [],
    action: ISetWalletAction,
) => {
    if (action.type === SET_WALLET) {
        if (isEmpty(action.payload)) {
            return DEFAULT_WALLETS;
        }
        return action.payload;
    }
    return state;
};
