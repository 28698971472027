import {createMuiTheme} from '@material-ui/core';

export const theme = createMuiTheme({
    overrides: {
        MuiTypography: {
            root: {
                color: '#545A6C',
                fontFamily: 'Barlow !important',
            },
            h1: {
                fontWeight: 600,
                fontSize: 48,
            },
            h2: {
                fontWeight: 600,
                fontSize: 36,
            },
            subtitle1: {
                fontWeight: 400,
                fontSize: 24,
            },
            subtitle2: {
                fontWeight: 'normal',
                fontSize: 22,
            },
            caption: {
                fontSize: 14,
                color: '#babbbc',
            },
            body1: {
                fontSize: 12,
                color: '#1e2b35',
            },
        },
        MuiButton: {
            root: {
                height: 40,
                minWidth: 170,
            },
            containedPrimary: {
                backgroundColor: '#ffab32',
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 13px) scale(1)',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '10.5px 14px',
            },
        },
        MuiTab: {
            root: {
                minWidth: '100px !important',
            },
            textColorPrimary: {
                color: '#113a56',
                fontFamily: 'Barlow',
                fontWeight: 'bold',
                textTransform: 'none',
                fontSize: 13,
                width: 'auto',
                '&$disabled': {
                    color: undefined,
                },
            },
        },
        MuiSlider: {
            root: {
                color: '#ffab32',
                height: 6,
            },
            thumb: {
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus, &:hover, &$active': {
                    boxShadow: 'inherit',
                },
            },
        },
    },
});
