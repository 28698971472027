import {store} from '..';
import {updateNotificationAction} from '../actions/notification';
import {AxiosError} from 'axios';

export const fireErrorNotification = (error: AxiosError | string) => {
    let message = '';
    if (typeof error === 'string') {
        message = error;
    } else {
        const responseErrors: any[] = Object.values(
            error.response?.data?.errors || [],
        );
        if (
            responseErrors.length &&
            typeof error.response?.data?.errors !== 'string' &&
            (typeof responseErrors[0][0] === 'string' ||
                typeof responseErrors[0] === 'string')
        ) {
            message =
                typeof responseErrors[0][0] === 'string'
                    ? responseErrors[0][0]
                    : typeof responseErrors[0] === 'string'
                    ? responseErrors[0]
                    : '';
        } else {
            message = error.response?.data?.message;
        }
    }

    store.dispatch(
        updateNotificationAction({
            isShow: true,
            type: 'error',
            message,
        }),
    );
};

export const fireSuccessNotification = (message?: string) => {
    if (message) {
        store.dispatch(
            updateNotificationAction({
                isShow: true,
                type: 'success',
                message,
            }),
        );
    }
};
