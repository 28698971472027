import {store} from '..';
import {setWithdraws, getWithdrawsRequest} from '../actions/withdraw';
import Axios, {AxiosResponse, AxiosError} from 'axios';

import {
    fireErrorNotification,
    fireSuccessNotification,
} from '../helpers/notification';
import {
    IWithdraw,
    IWithdrawAction,
    IWithdrawTransferAll,
} from '../types/withdraw';
import {IPaginationRequest} from '../types/pagination';

export const getWithdrawList = (params: IPaginationRequest) => {
    const {pageNumber, pageSize} = params;
    store.dispatch(getWithdrawsRequest());
    Axios.get(`/Withdraw/GetAll?PageNumber=${pageNumber}&PageSize=${pageSize}`)
        .then((response: AxiosResponse<IWithdraw>) => {
            store.dispatch(setWithdraws(response.data));
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const approveWithdraw = (params: IWithdrawAction, id: string) => {
    Axios.put(`/Withdraw/Approve/${id}`, params)
        .then((response: AxiosResponse<{message: string}>) => {
            fireSuccessNotification(response.data.message);
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
export const rejectWithdraw = (params: IWithdrawAction, id: string) => {
    Axios.put(`/Withdraw/Reject/${id}`, params)
        .then((response: AxiosResponse<{message: string}>) => {
            fireSuccessNotification(response.data.message);
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};

export const transferAll = (params: IWithdrawTransferAll) => {
    Axios.post(`/Withdraw/TransferAll`, params)
        .then((response: AxiosResponse<{message: string}>) => {
            fireSuccessNotification(response.data.message);
        })
        .catch((error: AxiosError) => {
            fireErrorNotification(error);
        });
};
