import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import {
    makeStyles,
    TextField,
    InputAdornment,
    Typography,
    Box,
    FormControl,
    Grid,
    // FormHelperText,
} from '@material-ui/core';

import {IRankData, IRankRequest} from '../../types/rank';
import {useDropzone} from 'react-dropzone';
import {map, has, isEmpty} from 'lodash';
import {camelToTitleCase} from '../../helpers/common';

///////////////////
const formStyles = makeStyles({
    formGroup: {
        marginBottom: 10,
        width: '100%',
    },
    title: {},
    imageContainer: {
        width: 200,
        height: 200,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: 5,
        cursor: 'pointer',
        border: '2px solid #ffab32',
        borderRadius: 5,
    },
    imageContent: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    iconAdd: {
        backgroundColor: '#3f51b5',
        color: '#FFF',
        marginLeft: 10,
        '&:hover': {
            backgroundColor: '#324092',
        },
    },
    titleAddContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    bagde: {
        fontSize: 13,
        backgroundColor: '#f0b90a',
        marginRight: 15,
        padding: '2px 5px',
        borderRadius: 5,
        color: '#FFF',
    },
});

interface IProps {
    rank: IRankData | null;
    nextLevel: number;
    ref: any;
}

const RankForm = forwardRef((props: IProps, ref: any) => {
    const {rank, nextLevel} = props;
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        multiple: false,
    });
    const [state, setState] = useState({
        name: {value: '', error: ''},
        level: {value: nextLevel > 0 ? nextLevel : 1, error: ''},
        percent: {value: 1, error: ''},
        nextLevelRequired: {value: 100, error: ''},
        image: {value: '', error: ''},
    });
    const [base64Image, setBase64Image] = useState('');

    useImperativeHandle(ref, () => ({
        getFormData: getFormData,
        validate: validateData,
    }));

    const getFormData = () => {
        const formData: IRankRequest = {
            name: state.name.value,
            level: state.level.value,
            percent: parseFloat((state.percent.value / 100).toFixed(5)),
            nextLevelRequired: state.nextLevelRequired.value,
        };
        if (!rank || rank.image !== state.image.value) {
            formData.base64Image = base64Image.split(',')[1];
        }
        return formData;
    };
    useEffect(() => {
        if (rank) {
            const newState = {
                name: {value: rank.name, error: ''},
                level: {value: rank.level, error: ''},
                percent: {
                    value: parseFloat((rank.percent * 100).toFixed(5)),
                    error: '',
                },
                nextLevelRequired: {value: rank.nextLevelRequired, error: ''},
                image: {value: rank.image ?? '', error: ''},
            };
            setState(newState);
        }
    }, [rank]);

    const validateData = () => {
        let newState = {...state};
        let isValid = true;
        map(newState, (attribute: any, key: string) => {
            if (has(attribute, 'value')) {
                if (attribute.value === '') {
                    isValid = false;
                    attribute.error = `${camelToTitleCase(
                        key,
                    )} can not be empty`;
                }
                return attribute;
            }
            return attribute.map((child: any, childKey: string) => {
                if (has(child, 'value')) {
                    if (child.value === '') {
                        isValid = false;
                        child.error = `${camelToTitleCase(
                            childKey,
                        )} can not be empty`;
                    }
                }
                return child;
            });
        });
        setState(newState);
        return isValid;
    };

    const onChangeState = (key: string, event: React.ChangeEvent<any>) => {
        event.persist();
        let value = event.target.value;
        // check if value is an number type
        if (!isNaN(parseFloat(value))) {
            if (value < 0) {
                value = 0;
            }
        }
        setState({
            ...state,
            [key]: {
                value: value,
                error: '',
            },
        });
    };

    useEffect(() => {
        if (!isEmpty(acceptedFiles)) {
            const chosenFile: File = acceptedFiles[0];
            const reader = new FileReader();
            reader.onload = () => {
                const image = reader.result as string;
                setState({
                    ...state,
                    image: {
                        value: image,
                        error: '',
                    },
                });
                setBase64Image(image);
            };
            reader.readAsDataURL(chosenFile);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles]);

    const classes = formStyles();
    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Typography variant="h6" className={classes.title}>
                        General
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Box
                        {...getRootProps({className: 'dropzone'})}
                        className={classes.imageContainer}
                    >
                        <input {...getInputProps()} />
                        {state.image.value && state.image.value !== '' ? (
                            <img
                                alt="rank"
                                src={state.image.value}
                                className={classes.imageContent}
                            />
                        ) : (
                            <p>
                                Drag and drop file here, or click to select file
                            </p>
                        )}
                    </Box>
                    {state.image.error ? (
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                            {state.image.error}
                        </p>
                    ) : null}
                </Grid>

                <Grid item md={8}>
                    <Grid container spacing={3}>
                        <Grid item md={12}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    value={state.name.value}
                                    error={state.name.error !== ''}
                                    helperText={state.name.error}
                                    onChange={(e) => onChangeState('name', e)}
                                    label="Name"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    type="number"
                                    value={state.level.value}
                                    error={state.level.error !== ''}
                                    helperText={state.level.error}
                                    onChange={(e) => onChangeState('level', e)}
                                    label="Level"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    value={state.percent.value}
                                    error={state.percent.error !== ''}
                                    helperText={state.percent.error}
                                    onChange={(e) =>
                                        onChangeState('percent', e)
                                    }
                                    label="Discount Percent"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span className={classes.bagde}>
                                                    %
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    type="number"
                                    value={state.nextLevelRequired.value}
                                    error={state.nextLevelRequired.error !== ''}
                                    helperText={state.nextLevelRequired.error}
                                    onChange={(e) =>
                                        onChangeState('nextLevelRequired', e)
                                    }
                                    label="Next Level Require"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span className={classes.bagde}>
                                                    USD
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
});

export default RankForm;
