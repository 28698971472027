import React, {useState} from 'react';
import {
    Grid,
    Box,
    Button,
    makeStyles,
    FormControl,
    Typography,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import {loginMiddleware} from '../../middlewares/auth';
import {ReCaptcha} from '../../components/ReCAPTCHA';
import {find} from 'lodash';
import {isValidEmail} from '../../helpers/validate';

const useStyles = makeStyles({
    wrapper: {
        height: `calc(100vh)`,
        position: 'relative',
        backgroundColor: '#f2f6ff',
    },
    loginPanel: {
        width: '30%',
        maxWidth: '100%',
        padding: '40px',
        boxShadow: '0 4px 8px 0 rgb(187 197 255 / 15%)',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#fff',
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#fff !important',
            height: 'auto !important',
            '& input': {
                height: 44,
                padding: '6px 14px',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f1f6ff',
            outline: 'none !important',
        },
        '& .MuiInputAdornment-root': {
            color: '#267fff',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c2d7fd',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c2d7fd',
            outline: 'none !important',
        },
    },
    formControl: {
        marginBottom: 25,
    },
    header: {
        marginBottom: 60,
        textAlign: 'center',
        '& .title': {
            fontSize: 40,
            fontWeight: 500,
            color: '#31507d',
        },
        '& .subtitle': {
            fontSize: 18,
            color: '#a1afc4',
        },
    },
    signinBtn: {
        textTransform: 'capitalize',
        fontSize: 18,
        height: 50,
        borderRadius: 8,
        fontWeight: 500,
        backgroundColor: '#267fff',
        marginTop: 40,
        '&.Mui-disabled': {
            backgroundColor: '#267fff',
            color: '#fff',
            opacity: 0.5,
        },
    },
});

export const Login = () => {
    const [state, setState] = useState({
        email: {value: '', error: ''},
        password: {value: '', error: ''},
        reCaptcha: {value: '', error: ''},
    });
    const classes = useStyles();
    const handleSubmit = () => {
        loginMiddleware({
            email: state.email.value.trim().toLocaleLowerCase(),
            password: state.password.value,
            googleReCaptchaResponse: state.reCaptcha.value,
        });
    };

    const onChangeText = (key: string) => (event: any) => {
        let error = '';
        if (key === 'password' && event.target.value.length < 6) {
            error = 'Password too short';
        }
        if (key === 'email' && isValidEmail(event.target.value) === false) {
            error = 'Email is invalid';
        }
        setState({
            ...state,
            [key]: {
                value: event.target.value,
                error,
            },
        });
    };

    const onChangeReCaptcha = (token: string | null) =>
        setState({
            ...state,
            reCaptcha: {
                value: token || '',
                error: '',
            },
        });

    const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && disableSubmit === false) {
            event.preventDefault();
            handleSubmit();
        }
    };

    const disableSubmit = Boolean(
        find(state, (el) => el.value === '' || el.error !== ''),
    );

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.wrapper}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Box className={classes.loginPanel}>
                    <Box className={classes.header}>
                        <Typography className="title">Welcome Back</Typography>
                        <Typography className="subtitle">
                            Enter your credentials to access your account.
                        </Typography>
                    </Box>
                    <FormControl fullWidth className={classes.formControl}>
                        <TextField
                            type="email"
                            helperText={state.email.error}
                            error={state.email.error ? true : false}
                            onKeyPress={onKeyPress}
                            value={state.email.value}
                            onChange={onChangeText('email')}
                            fullWidth
                            variant="outlined"
                            placeholder="Email"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl}>
                        <TextField
                            type="password"
                            value={state.password.value}
                            helperText={state.password.error}
                            error={state.password.error ? true : false}
                            onKeyPress={onKeyPress}
                            onChange={onChangeText('password')}
                            fullWidth
                            variant="outlined"
                            placeholder="Password"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    <ReCaptcha onChange={onChangeReCaptcha} />

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        disabled={disableSubmit}
                        className={classes.signinBtn}
                        disableElevation
                    >
                        Sign In
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};
