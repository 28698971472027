import React, {useEffect} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import {PATH} from './constants/path';
import {Login} from './pages/auth/Login';
import {includes} from 'lodash';
import {ForgotPassword} from './pages/auth/ForgotPassword';
import {Dashboard} from './pages/dashboard';
import {AggregateBalance} from './pages/aggregate-balance';
import {ResetPassword} from './pages/auth/ResetPassword';
import {SignUp} from './pages/auth/SignUp';
import {TwoFactorAuthentication} from './pages/auth/TwoFactorAuthentication';
import {Settings} from './pages/settings';
import {Users} from './pages/user';
import {UserShow} from './pages/user/show';
import {Plans} from './pages/plan';
import {Ranks} from './pages/rank';
import {Withdraw} from './pages/withdraw';
import {Coins} from './pages/coin';
import {Discounts} from './pages/discount';

import {
    SignUpSuccess,
    ForgotPasswordSuccess,
} from './pages/auth/SuccessResponse';
import {
    get2FactorAuthKey,
    getUserProfileMiddleware,
    getUserWalletsMiddleware,
} from './middlewares/setting';
import {getCoins} from './middlewares/coin';
import {useSelector} from 'react-redux';
import {StoreState} from './types/store-state';
import {VerifyEmail} from './pages/auth/VerifyEmail';

import {isString} from 'lodash';

export const Routes = () => {
    const history = useHistory();
    const access = localStorage.getItem('token');
    const secretCode = sessionStorage.getItem('secretCode');
    const {userProfile} = useSelector((state: StoreState) => state);
    // global API
    useEffect(() => {
        getUserProfileMiddleware();
    }, []);

    useEffect(() => {
        if (!access) {
            if (
                !includes(
                    [
                        PATH.signUp,
                        PATH.signUpSuccess,
                        PATH.forgotPassword,
                        PATH.forgotPasswordSuccess,
                        PATH.verifyEmail,
                    ],
                    history.location.pathname,
                ) &&
                !includes(
                    history.location.pathname,
                    PATH.resetPassword.replace('/:token', ''),
                )
            ) {
                if (isString(secretCode)) {
                    return history.push(PATH.login2fa);
                }
                return history.push(PATH.login);
            }
        } else {
            if (
                includes(
                    [
                        PATH.login,
                        PATH.home,
                        PATH.forgotPassword,
                        PATH.forgotPasswordSuccess,
                        PATH.signUpSuccess,
                    ],
                    history.location.pathname,
                )
            ) {
                return history.push(PATH.plans);
            }
        }
    }, [access, history, secretCode]);

    useEffect(() => {
        if (userProfile) {
            getCoins();
            getUserWalletsMiddleware();
            if (userProfile.twoFactorEnabled === false) {
                get2FactorAuthKey();
            }
        }
    }, [userProfile]);

    return (
        <Switch>
            <Route path={PATH.login} exact component={Login} />
            <Route
                path={PATH.forgotPassword}
                exact
                component={ForgotPassword}
            />
            <Route
                path={PATH.forgotPasswordSuccess}
                exact
                component={ForgotPasswordSuccess}
            />
            // <Route path={PATH.dashboard} exact component={Dashboard} />
            <Route
                path={PATH.aggregateBalance}
                exact
                component={AggregateBalance}
            />
            <Route path={PATH.settings} component={Settings} />
            <Route path={PATH.users} component={Users} />
            <Route path={PATH.userShow} component={UserShow} />
            <Route path={PATH.plans} component={Plans} />
            <Route path={PATH.ranks} component={Ranks} />
            <Route path={PATH.withdraw} component={Withdraw} />
            <Route path={PATH.coins} component={Coins} />
            <Route path={PATH.discounts} component={Discounts} />
            //
            <Route path={PATH.resetPassword} component={ResetPassword} />
            <Route path={PATH.signUpSuccess} exact component={SignUpSuccess} />
            <Route path={PATH.signUp} exact component={SignUp} />
            <Route path={PATH.verifyEmail} exact component={VerifyEmail} />
            <Route path={PATH.login2fa} component={TwoFactorAuthentication} />
        </Switch>
    );
};
