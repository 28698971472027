import {Action} from 'redux';
import {IRank} from '../types/rank';

////////////////////////////////////////////////////////
export const GET_RANKS_REQUEST = 'GET_RANKS_REQUEST';
export type GET_RANKS_REQUEST = typeof GET_RANKS_REQUEST;

export interface IGetRanksRequestAction extends Action<GET_RANKS_REQUEST> {}
export const getRanksRequest = (): IGetRanksRequestAction => {
    return {
        type: GET_RANKS_REQUEST,
    };
};

////////////////////////////////////////////////////////
export const SET_RANKS_RESPONSE = 'SET_RANKS_RESPONSE';
export type SET_RANKS_RESPONSE = typeof SET_RANKS_RESPONSE;

export interface ISetRanksResponseAction extends Action<SET_RANKS_RESPONSE> {
    payload: IRank;
}

export const setRanks = (payload: IRank): ISetRanksResponseAction => {
    return {
        type: SET_RANKS_RESPONSE,
        payload,
    };
};
