import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {useSelector} from 'react-redux';
import {StoreState} from '../types/store-state';
import {updateNotificationAction} from '../actions/notification';
import {useDispatch} from 'react-redux';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';

const Alert = (props: AlertProps) => (
    <MuiAlert elevation={6} variant="filled" {...props} />
);

export const Notification = () => {
    const notificationState = useSelector(
        (state: StoreState) => state.notification,
    );
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(
            updateNotificationAction({
                ...notificationState,
                isShow: false,
            }),
        );
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={notificationState.isShow}
            autoHideDuration={3000}
            onClose={handleClose}
            message={notificationState.message}
        >
            <Alert onClose={handleClose} severity={notificationState.type}>
                {notificationState.message}
            </Alert>
        </Snackbar>
    );
};
