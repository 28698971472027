import React, {useState} from 'react';
import {Grid, Typography, Button, makeStyles, Link} from '@material-ui/core';
import {find} from 'lodash';
import {ReCaptcha} from '../../components/ReCAPTCHA';
import {Input} from '../../components/Input';
import {resetPasswordMiddleware} from '../../middlewares/auth';
import {PATH} from '../../constants/path';

const styles = makeStyles({
    autofill: {
        '& .MuiOutlinedInput-input:-webkit-autofill': {
            borderRadius: 3,
            padding: 12,
        },
    },
});

export const ResetPassword = ({location}) => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token') || '';
    const id = urlParams.get('id') || '';
    const classes = styles();
    const [isSuccess, setSuccess] = useState(false);

    const SuccessContent = () => (
        <>
            <Typography variant="h1" style={{color: 'white'}}>
                Reset password successfully!
            </Typography>
            <Link href={PATH.login}>Go back to login page</Link>
        </>
    );

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
                height: `calc(100vh)`,
                position: 'relative',
                backgroundColor: 'lightGrey',
            }}
            className={classes.autofill}
        >
            <img
                src={''}
                alt="Logo"
                style={{position: 'absolute', top: 40, left: 40}}
            />

            {isSuccess ? (
                <SuccessContent />
            ) : (
                <FormContent setSuccess={setSuccess} token={token} id={id} />
            )}
        </Grid>
    );
};

const FormContent = React.memo(
    (props: {setSuccess: any; token: string; id: string}) => {
        const [state, setState] = useState({
            password: {value: '', error: ''},
            repassword: {value: '', error: ''},
            reCaptcha: {value: '', error: ''},
        });

        const handleSubmit = async () => {
            resetPasswordMiddleware({
                password: state.password.value,
                token: props.token,
                id: props.id,
                callback: () => props.setSuccess(true),
            });
        };

        const onChangeText = (key: string) => (event) => {
            if (key === 'password') {
                setState({
                    ...state,
                    password: {
                        error:
                            event.target.value.length < 6
                                ? 'Password is too short'
                                : '',
                        value: event.target.value,
                    },
                    repassword: {
                        error: !state.repassword.value
                            ? ''
                            : event.target.value.length < 6
                            ? 'Confirm password is too short'
                            : event.target.value !== state.repassword.value
                            ? 'Password is not match'
                            : '',
                        value: state.repassword.value,
                    },
                });
            }
            if (key === 'repassword') {
                setState({
                    ...state,
                    password: {
                        error:
                            state.password.value.length < 6
                                ? 'Password is too short'
                                : '',
                        value: state.password.value,
                    },
                    repassword: {
                        error: !state.repassword.value
                            ? ''
                            : event.target.value.length < 6
                            ? 'Confirm password is too short'
                            : event.target.value !== state.password.value
                            ? 'Password not match'
                            : '',
                        value: event.target.value,
                    },
                });
            }
        };

        const onChangeReCaptcha = (token: string | null) =>
            setState({
                ...state,
                reCaptcha: {
                    value: token || '',
                    error: '',
                },
            });

        const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Enter' && disableSubmit === false) {
                event.preventDefault();
                handleSubmit();
            }
        };

        const disableSubmit = Boolean(
            find(state, (el) => el.value === '' || el.error !== ''),
        );

        return (
            <>
                <Typography variant="h1" style={{color: 'white'}}>
                    Reset password
                </Typography>
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    style={{
                        backgroundColor: 'white',
                        height: 430,
                        width: 444,
                        marginTop: 28,
                        marginBottom: 20,
                        padding: '40px 30px',
                        boxShadow: '0px 0px 100px #162CA7',
                        borderRadius: 3,
                    }}
                >
                    <Input
                        label="Password"
                        type="password"
                        value={state.password.value}
                        helperText={state.password.error}
                        error={state.password.error ? true : false}
                        onChange={onChangeText('password')}
                        onKeyPress={onKeyPress}
                    />

                    <Input
                        label="Confirm password"
                        type="password"
                        value={state.repassword.value}
                        helperText={state.repassword.error}
                        error={state.repassword.error ? true : false}
                        onChange={onChangeText('repassword')}
                        onKeyPress={onKeyPress}
                    />

                    <ReCaptcha
                        onChange={onChangeReCaptcha}
                        containerStyle={{marginTop: 20}}
                    />

                    <Grid container justify="center" style={{marginTop: 30}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={disableSubmit}
                        >
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </>
        );
    },
);
