import {IPlan} from '../types/plan';
import {
    GET_PLANS_REQUEST,
    IGetPlansRequestAction,
    SET_PLANS_RESPONSE,
    ISetPlansResponseAction,
} from '../actions/plan';
import {DEFAULT_PAGE_SIZE} from '../constants/pagination';

const initState: IPlan = {
    data: [],
    pageInfo: {
        totalCount: 0,
        currentPage: 1,
        totalPages: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        searchText: '',
    },
    isLoading: false,
};

export const plan = (
    state: IPlan = initState,
    action: ISetPlansResponseAction | IGetPlansRequestAction,
) => {
    if (action.type === GET_PLANS_REQUEST) {
        return {
            ...state,
            isLoading: true,
        };
    }

    if (action.type === SET_PLANS_RESPONSE) {
        return {
            ...state,
            data: action.payload.data,
            pageInfo: action.payload.pageInfo,
            isLoading: false,
        };
    }
    return state;
};
