import React, {useState, useEffect} from 'react';
import {Grid, Typography, Button} from '@material-ui/core';
import {Input} from '../../components/Input';
import {
    activeTwoFactor,
    deactiveTwoFactor,
    getUserProfileMiddleware,
} from '../../middlewares/setting';
import {TwoFactor} from '../../types/setting';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';

export const TwoFactorAuth = () => {
    const {userProfile, userTwoFactor} = useSelector(
        (state: StoreState) => state,
    );

    const [twoFactor, setTwoFactor] = useState<TwoFactor>({
        manualEntryKey: '',
        qrCodeSetupImageUrl: '',
    });
    const [code, setCode] = useState('');
    const isTwoFactorEnabled = userProfile?.twoFactorEnabled;

    useEffect(() => {
        if (userProfile && userTwoFactor) {
            if (userProfile.twoFactorEnabled === false) {
                setTwoFactor(userTwoFactor);
            }
        }
        // eslint-disable-next-line
    }, [userProfile, userTwoFactor]);

    const handleSubmit = () => {
        if (userProfile?.twoFactorEnabled === true) {
            deactiveTwoFactor(code, () => {
                setCode('');
                getUserProfileMiddleware();
            });
            return;
        }
        activeTwoFactor(code, () => {
            setCode('');
            getUserProfileMiddleware();
        });
    };

    const ContentWithout2FASecret = () => {
        return (
            <Grid item xs={5}>
                <Typography variant="body1" style={{marginTop: 30}}>
                    2Factor Authentication is enabled. Please enter your
                    Confirmation Code below to deactivate 2Factor
                    Authentication:
                </Typography>
                <Input
                    value={code}
                    onChange={(event) => setCode(event.target.value)}
                    style={{marginTop: 20, width: 250}}
                />
            </Grid>
        );
    };

    const ContentWith2FASecret = () => {
        return (
            <>
                <Grid item xs={5}>
                    <Typography variant="body1">
                        To use 2Factor Authentication, enter the following code
                        manually into your Google Authenticator app:
                    </Typography>
                    <Input
                        disabled
                        value={twoFactor.manualEntryKey}
                        style={{marginTop: 20, width: 250}}
                    />

                    <Typography variant="body1" style={{marginTop: 30}}>
                        2Factor Authentication is not enabled yet. Please enter
                        your Confirmation Code below to activate 2Factor
                        Authentication:
                    </Typography>
                    <Input
                        value={code}
                        onChange={(event) => setCode(event.target.value)}
                        style={{marginTop: 20, width: 250}}
                    />
                </Grid>
                <Grid item xs={7} style={{paddingLeft: 40}}>
                    <Typography variant="body1">
                        To use 2 Factor Authentication you are able to just scan
                        QR code:
                    </Typography>
                    <Grid container justify="center">
                        {twoFactor.qrCodeSetupImageUrl && (
                            <img
                                src={twoFactor.qrCodeSetupImageUrl}
                                alt="qr-code"
                                style={{marginTop: 20, height: 200, width: 200}}
                            />
                        )}
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <Grid container>
            {isTwoFactorEnabled
                ? ContentWithout2FASecret()
                : ContentWith2FASecret()}

            <Grid container justify="center" style={{marginTop: 30}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={code === ''}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};
