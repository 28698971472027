import React, {useState, useRef} from 'react';
import {PageLayout} from '../../components/PageLayout';
import {makeStyles, Box, Typography, Button, Link} from '@material-ui/core';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import CoinForm from './CoinForm';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {ICoin, ICoinRequest} from '../../types/coin';
import {getCoinsByPagination, editCoin, addCoin} from '../../middlewares/coin';
import {PATH} from '../../constants/path';
import EditIcon from '@material-ui/icons/Create';

const useStyles = makeStyles({
    contentWrapper: {
        width: '100%',
        '& .MuiButton-startIcon': {
            marginRight: -10,
        },
    },
    titleContainer: {
        width: 400,
        display: 'flex',
        '& h5': {
            marginRight: 20,
        },
    },
});

export const Coins = () => {
    const {coinList} = useSelector((state: StoreState) => state);
    const coinFormRef = useRef<any>();
    const classes = useStyles();
    const [columns] = useState([
        {title: 'Name', field: 'name'},
        {title: 'Code', field: 'code'},
        {title: 'Algorithm', field: 'algorithm'},
        {title: 'Power Unit', field: 'powerUnit'},
        {
            title: 'Wallet Creation URL',
            field: 'createWalletUrl',
            render: (rowData: ICoin) => {
                return (
                    <Link href={rowData.createWalletUrl} target="_blank">
                        {rowData.createWalletUrl}
                    </Link>
                );
            },
        },
        {
            title: 'Is Active',
            field: 'actFlag',
            render: (rowData: ICoin) => {
                if (rowData.actFlag) {
                    return 'Yes';
                }
                return 'No';
            },
        },
        {
            title: 'Frozen',
            field: 'frozenFlag',
            render: (rowData: ICoin) => {
                if (rowData.frozenFlag) {
                    return 'Yes';
                }
                return 'No';
            },
        },
    ]);

    const [modalTitle, setModalTitle] = useState<string>('Add Plan');
    const [coinSelected, setCoinSelected] = useState<ICoin | null>(null);

    const [open, setOpen] = useState<boolean>(false);

    const handleSubmit = () => {
        const isFormValid = coinFormRef.current.validate();
        if (!isFormValid) {
            return; /// do nothing
        }
        const formData: ICoinRequest = coinFormRef.current.getFormData();
        // check type of request
        if (coinSelected) {
            editCoin(formData, coinSelected.id);
        } else {
            addCoin(formData);
        }
        setOpen(false);
    };

    const handleAddPlan = (_event: any) => {
        setModalTitle('Add Coin');
        setCoinSelected(null);
        setOpen(true);
    };

    const handleEditCoin = (_event: any, rowData: ICoin) => {
        setModalTitle('Edit Coin');
        setCoinSelected(rowData);
        setOpen(true);
    };

    return (
        <PageLayout>
            <Box className={classes.contentWrapper}>
                <Table
                    headers={columns}
                    data={coinList.data}
                    loadAction={getCoinsByPagination}
                    page={coinList.pageInfo.currentPage}
                    limit={coinList.pageInfo.pageSize}
                    count={coinList.pageInfo.totalCount}
                    isLoading={coinList.isLoading}
                    path={PATH.plans}
                    searchText={coinList.pageInfo.searchText}
                    title={
                        <Box className={classes.titleContainer}>
                            <Typography variant="h5">Coin</Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={handleAddPlan}
                            >
                                Add Coin
                            </Button>
                        </Box>
                    }
                    actions={[
                        {
                            icon: () => <EditIcon />,
                            tooltip: 'Edit Coin',
                            onClick: handleEditCoin,
                        },
                    ]}
                />
            </Box>
            <Box>
                <Modal
                    maxWidth={'md'}
                    open={open}
                    handleClose={() => setOpen(false)}
                    handleSubmit={handleSubmit}
                    hasClose
                    title={modalTitle}
                >
                    <CoinForm coin={coinSelected} ref={coinFormRef} />
                </Modal>
            </Box>
        </PageLayout>
    );
};
