import React, {useState} from 'react';
import {PageLayout} from '../../components/PageLayout';
import {makeStyles, Box} from '@material-ui/core';
import Table from '../../components/Table';
import {pushTo} from '../../helpers/history';
import {PATH} from '../../constants/path';
import {useSelector} from 'react-redux';
import {StoreState} from '../../types/store-state';
import {IUserData} from '../../types/user';
import {getUsers} from '../../middlewares/user';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';

const useStyles = makeStyles({
    contentWrapper: {
        width: '100%',
        '& .MuiButton-startIcon': {
            marginRight: -10,
        },
    },
});

export const Users = () => {
    const {user} = useSelector((state: StoreState) => state);
    const classes = useStyles();
    const [columns] = useState([
        {
            title: 'Email',
            field: 'email',
        },
        {
            title: 'Full Name',
            field: 'firstName',
            render: (rowData: IUserData) => {
                if (rowData.firstName && rowData.lastName) {
                    return `${rowData.firstName} ${rowData.lastName}`;
                }
                return 'N/A';
            },
        },
        {
            title: 'Phone Number',
            field: 'phoneNumber',
            render: (rowData: IUserData) => {
                return rowData.phoneNumber ?? 'N/A';
            },
        },
        {
            title: 'Created At',
            field: 'createAt',
            render: (rowData: IUserData) => {
                return moment(rowData.createAt).format('LLLL');
            },
        },
    ]);

    return (
        <PageLayout>
            <Box className={classes.contentWrapper}>
                <Table
                    headers={columns}
                    data={user.data}
                    loadAction={getUsers}
                    page={user.pageInfo.currentPage}
                    limit={user.pageInfo.pageSize}
                    count={user.pageInfo.totalCount}
                    title="Users"
                    isLoading={user.isLoading}
                    path={PATH.users}
                    searchText={user.pageInfo.searchText}
                    actions={[
                        {
                            icon: () => <VisibilityIcon />,
                            tooltip: 'View Infomation',
                            onClick: (_event: any, rowData: IUserData) => {
                                pushTo(PATH.userShow, {id: rowData.id});
                            },
                        },
                    ]}
                />
            </Box>
        </PageLayout>
    );
};
