import {IRank} from '../types/rank';
import {
    GET_RANKS_REQUEST,
    IGetRanksRequestAction,
    SET_RANKS_RESPONSE,
    ISetRanksResponseAction,
} from '../actions/rank';
import {DEFAULT_PAGE_SIZE} from '../constants/pagination';

const initState: IRank = {
    data: [],
    pageInfo: {
        totalCount: 0,
        currentPage: 1,
        totalPages: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        searchText: '',
    },
    isLoading: false,
};

export const rank = (
    state: IRank = initState,
    action: ISetRanksResponseAction | IGetRanksRequestAction,
) => {
    if (action.type === GET_RANKS_REQUEST) {
        return {
            ...state,
            isLoading: true,
        };
    }

    if (action.type === SET_RANKS_RESPONSE) {
        return {
            ...state,
            data: action.payload.data,
            pageInfo: action.payload.pageInfo,
            isLoading: false,
        };
    }
    return state;
};
