import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import {
    makeStyles,
    TextField,
    Typography,
    FormControl,
    Grid,
    InputAdornment,
} from '@material-ui/core';
import {IDiscount, IDiscountRequest} from '../../types/discount';
import {map, has} from 'lodash';
import {camelToTitleCase} from '../../helpers/common';
import {KeyboardDateTimePicker} from '@material-ui/pickers';
import moment from 'moment';

///////////////////
const formStyles = makeStyles({
    formGroup: {
        marginBottom: 10,
        width: '100%',
    },
    title: {},
    imageContainer: {
        width: 200,
        height: 200,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: 5,
        cursor: 'pointer',
        border: '2px solid #ffab32',
        borderRadius: 5,
    },
    imageContent: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    iconAdd: {
        backgroundColor: '#3f51b5',
        color: '#FFF',
        marginLeft: 10,
        '&:hover': {
            backgroundColor: '#324092',
        },
    },
    titleAddContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    bagde: {
        fontSize: 13,
        backgroundColor: '#f0b90a',
        marginRight: 15,
        padding: '2px 5px',
        borderRadius: 5,
        color: '#FFF',
    },
});

interface IProps {
    discount: IDiscount | null;
    ref: any;
}

const DiscountForm = forwardRef((props: IProps, ref: any) => {
    const {discount} = props;

    const [state, setState] = useState({
        name: {value: '', error: ''},
        percent: {value: 0, error: ''},
        limit: {value: 0, error: ''},
        validUntil: {value: '', error: ''},
    });

    useImperativeHandle(ref, () => ({
        getFormData: getFormData,
        validate: validateData,
    }));

    const getFormData = () => {
        const formData: IDiscountRequest = {
            name: state.name.value,
            percent: parseFloat((state.percent.value / 100).toFixed(5)),
            limit: state.limit.value,
            validUntil: state.validUntil.value,
        };

        return formData;
    };

    useEffect(() => {
        if (discount) {
            const newState = {
                name: {value: discount.name, error: ''},
                percent: {
                    value: parseFloat((discount.percent * 100).toFixed(5)),
                    error: '',
                },
                limit: {value: discount.limit, error: ''},
                validUntil: {
                    value: moment(discount.validUntil)
                        .utc()
                        .format('yyyy-MM-DD HH:mm'),
                    error: '',
                },
            };
            setState(newState);
        }
    }, [discount]);

    const validateData = () => {
        let newState = {...state};
        let isValid = true;
        map(newState, (attribute: any, key: string) => {
            if (has(attribute, 'value')) {
                if (attribute.value === '') {
                    isValid = false;
                    attribute.error = `${camelToTitleCase(
                        key,
                    )} can not be empty`;
                }
                return attribute;
            }
            return attribute.map((child: any, childKey: string) => {
                if (has(child, 'value')) {
                    if (child.value === '') {
                        isValid = false;
                        child.error = `${camelToTitleCase(
                            childKey,
                        )} can not be empty`;
                    }
                }
                return child;
            });
        });
        setState(newState);
        return isValid;
    };

    const onChangeState = (key: string, event: React.ChangeEvent<any>) => {
        event.persist();
        let value = event.target.value;
        // check if value is an number type
        if (!isNaN(parseFloat(value))) {
            if (value < 0) {
                value = 0;
            }
        }
        setState({
            ...state,
            [key]: {
                value: value,
                error: '',
            },
        });
    };

    const classes = formStyles();
    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Typography variant="h6" className={classes.title}>
                        General
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item md={8}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    value={state.name.value}
                                    error={state.name.error !== ''}
                                    helperText={state.name.error}
                                    onChange={(e) => onChangeState('name', e)}
                                    label="Name"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl className={classes.formGroup}>
                                <KeyboardDateTimePicker
                                    ampm={false}
                                    inputVariant="outlined"
                                    label="Valid Until"
                                    value={
                                        state.validUntil.value
                                            ? moment(state.validUntil.value)
                                            : moment().add(7, 'days')
                                    }
                                    onChange={(date) => {
                                        setState({
                                            ...state,
                                            validUntil: {
                                                value:
                                                    date?.format(
                                                        'yyyy-MM-DD HH:mm',
                                                    ) ?? '',
                                                error: '',
                                            },
                                        });
                                    }}
                                    disablePast
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    format="DD-MM-yyyy HH:mm"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    label="Percent"
                                    type="number"
                                    value={state.percent.value}
                                    error={state.percent.error !== ''}
                                    helperText={state.percent.error}
                                    onChange={(e) =>
                                        onChangeState('percent', e)
                                    }
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span className={classes.bagde}>
                                                    %
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6}>
                            <FormControl className={classes.formGroup}>
                                <TextField
                                    label="Limit"
                                    type="number"
                                    value={state.limit.value}
                                    error={state.limit.error !== ''}
                                    helperText={state.limit.error}
                                    onChange={(e) => onChangeState('limit', e)}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
});

export default DiscountForm;
