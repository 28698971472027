import React from 'react';
import {
    Grid,
    AppBar,
    Toolbar,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    makeStyles,
    IconButton,
} from '@material-ui/core';
import {
    ExpandLess,
    ExpandMore,
    AcUnit as AcUnitIcon,
    PowerSettingsNew,
    AccountBox,
    LocalAtm as LocalAtmIcon,
    Payment as PaymentIcon,
    PermContactCalendar as PermContactCalendarIcon,
    Camera as CameraIcon,
    DeviceHub as DeviceHubIcon,
    MoneyOff as MoneyOffIcon,
} from '@material-ui/icons';
import {PATH} from '../constants/path';
import {PeopleIcon} from '../constants/image';
import {SVGImage} from './SVGImage';
import {pushTo} from '../helpers/history';
import {REACT_APP_SITE_NAME} from '../helpers/environment';

interface Props {
    style?: React.CSSProperties;
    children: any;
}

interface MenuItem {
    title: string;
    path: string;
    icon?: any;
    muiIcon?: any;
    params?: any;
    sub?: MenuItem[];
}

export const MENU_LIST: MenuItem[] = [
    {
        title: 'Aggregate Balance',
        path: PATH.aggregateBalance,
        icon: PeopleIcon,
        muiIcon: AcUnitIcon,
    },
    // {
    //     title: 'Dashboard',
    //     path: PATH.dashboard,
    //     icon: PeopleIcon,
    //     muiIcon: Dashboard,
    // },
    {
        title: 'Plan Management',
        path: PATH.plans,
        icon: PeopleIcon,
        muiIcon: CameraIcon,
    },
    {
        title: 'Discount Management',
        path: PATH.discounts,
        icon: PeopleIcon,
        muiIcon: MoneyOffIcon,
    },
    {
        title: 'Coin Management',
        path: PATH.coins,
        icon: PeopleIcon,
        muiIcon: LocalAtmIcon,
    },
    {
        title: 'Withdraw Management',
        path: PATH.withdraw,
        icon: PeopleIcon,
        muiIcon: PaymentIcon,
    },
    {
        title: 'User Management',
        path: PATH.users,
        icon: PeopleIcon,
        muiIcon: PermContactCalendarIcon,
        params: {
            page: 1,
            search: '',
        },
    },
    {
        title: 'Rank Management',
        path: PATH.ranks,
        icon: PeopleIcon,
        muiIcon: DeviceHubIcon,
        params: {
            page: 1,
            search: '',
        },
    },
    {
        title: 'My Account',
        path: PATH.generalInformation,
        icon: PeopleIcon,
        muiIcon: AccountBox,
    },
];

export const NAVBAR_HEIGHT = 64;
export const DRAWER_WIDTH = 280;

const useStyles = makeStyles({
    leftMenu: {
        '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            paddingTop: NAVBAR_HEIGHT,
            borderRight: 'none',
        },
    },
    hoverText: {
        color: 'white',
        '&:hover': {
            color: 'rgb(255, 171, 50)',
        },
    },
    hoverBefore: {
        '&:hover': {
            borderLeft: '3px solid #ffab32',
            paddingLeft: 13,
        },
    },
    subHover: {
        paddingLeft: 30,
        '&:hover': {
            paddingLeft: 27,
        },
    },
});

export const PageLayout = (props: Props) => {
    const classes = useStyles();
    return (
        <Grid
            container
            style={{
                height: '100vh',
                width: '100%',
                backgroundColor: '#f9f9fa',
                flexWrap: 'initial',
            }}
        >
            <AppBar
                variant="elevation"
                style={{
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 4px',
                }}
            >
                <Toolbar
                    style={{
                        justifyContent: 'space-between',
                        backgroundColor: '#f9f9fa',
                        height: NAVBAR_HEIGHT,
                    }}
                >
                    <Typography variant="h5">
                        {REACT_APP_SITE_NAME} Admin
                    </Typography>
                    <ProfileAction />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                anchor="left"
                style={{
                    width: 280,
                    height: '100%',
                    backgroundColor: '#274c65',
                }}
                className={classes.leftMenu}
            >
                <MenuList />
            </Drawer>

            <Grid
                container
                style={{
                    padding: `${NAVBAR_HEIGHT + 20}px 20px 20px`,
                    width: `calc(100% - ${DRAWER_WIDTH}px)`,
                    overflowX: 'hidden',
                    minHeight: `calc(100% - ${NAVBAR_HEIGHT + 40}px)`,
                    ...props.style,
                }}
            >
                {props.children}
            </Grid>
        </Grid>
    );
};

const MenuList = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const renderMenu = (menu: MenuItem[], isSub?: boolean) => {
        const onClickMenuItem = (item: MenuItem) => () => {
            if (item.sub) {
                setOpen(!open);
            } else {
                pushTo(item.path, item.params ?? {});
            }
        };

        return (
            <List style={{width: '100%'}}>
                {menu.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <ListItem
                                button
                                onClick={onClickMenuItem(item)}
                                className={`${classes.hoverBefore} ${
                                    isSub && classes.subHover
                                }`}
                            >
                                <ListItemIcon>
                                    {(
                                        <item.muiIcon
                                            style={{color: 'white'}}
                                        />
                                    ) || (
                                        <SVGImage
                                            src={item.icon}
                                            height={20}
                                            width={20}
                                        />
                                    )}
                                </ListItemIcon>

                                <ListItemText>
                                    <Typography
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: 16,
                                        }}
                                        className={classes.hoverText}
                                    >
                                        {item.title}
                                    </Typography>
                                </ListItemText>

                                {item.sub &&
                                    (open ? (
                                        <ExpandLess
                                            className={classes.hoverText}
                                        />
                                    ) : (
                                        <ExpandMore
                                            className={classes.hoverText}
                                        />
                                    ))}
                            </ListItem>

                            {item.sub && (
                                <Collapse
                                    in={open}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    {renderMenu(item.sub, true)}
                                </Collapse>
                            )}
                        </React.Fragment>
                    );
                })}
            </List>
        );
    };

    return (
        <Grid
            container
            style={{
                width: DRAWER_WIDTH,
                backgroundColor: 'transparent',
            }}
        >
            {renderMenu(MENU_LIST)}
        </Grid>
    );
};

const ProfileAction = () => {
    const handleLogout = () => {
        localStorage.clear();
        pushTo(PATH.login);
    };

    return (
        <Grid container alignItems="center" style={{width: 'auto'}}>
            <IconButton onClick={handleLogout}>
                <PowerSettingsNew />
            </IconButton>
        </Grid>
    );
};
