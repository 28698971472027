import {
    IGetDiscountListRequestAction,
    ISetDiscountListResponseAction,
    GET_DISCOUNT_LIST_REQUEST,
    SET_DISCOUNT_LIST_RESPONSE,
    SET_DISCOUNT,
    ISetDiscountAction,
} from '../actions/discount';
import {IDiscountList, IDiscount} from '../types/discount';
import {DEFAULT_PAGE_SIZE} from '../constants/pagination';

const initState: IDiscountList = {
    data: [],
    pageInfo: {
        totalCount: 0,
        currentPage: 1,
        totalPages: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        searchText: '',
    },
    isLoading: false,
};

export const discountList = (
    state: IDiscountList = initState,
    action: IGetDiscountListRequestAction | ISetDiscountListResponseAction,
) => {
    if (action.type === GET_DISCOUNT_LIST_REQUEST) {
        return {
            ...state,
            isLoading: true,
        };
    }

    if (action.type === SET_DISCOUNT_LIST_RESPONSE) {
        return {
            ...state,
            data: action.payload.data,
            pageInfo: action.payload.pageInfo,
            isLoading: false,
        };
    }
    return state;
};

export const discounts = (
    state: IDiscount[] = [],
    action: ISetDiscountAction,
) => {
    if (action.type === SET_DISCOUNT) {
        return action.payload;
    }
    /// default state
    return state;
};
